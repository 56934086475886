*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-0\.5 {
  top: .125rem;
  bottom: .125rem;
}

.inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.inset-y-1\.5 {
  top: .375rem;
  bottom: .375rem;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.inset-y-1\/3 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.inset-y-2\.5 {
  top: .625rem;
  bottom: .625rem;
}

.inset-y-2\/3 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.inset-y-3\.5 {
  top: .875rem;
  bottom: .875rem;
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-y-full {
  top: 100%;
  bottom: 100%;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-36 {
  bottom: 9rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-44 {
  bottom: 11rem;
}

.bottom-48 {
  bottom: 12rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-52 {
  bottom: 13rem;
}

.bottom-56 {
  bottom: 14rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-60 {
  bottom: 15rem;
}

.bottom-64 {
  bottom: 16rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-72 {
  bottom: 18rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-80 {
  bottom: 20rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.bottom-96 {
  bottom: 24rem;
}

.bottom-px {
  bottom: 1px;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-10 {
  left: 2.5rem;
}

.left-11 {
  left: 2.75rem;
}

.left-12 {
  left: 3rem;
}

.left-14 {
  left: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-20 {
  left: 5rem;
}

.left-24 {
  left: 6rem;
}

.left-28 {
  left: 7rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-32 {
  left: 8rem;
}

.left-36 {
  left: 9rem;
}

.left-4 {
  left: 1rem;
}

.left-40 {
  left: 10rem;
}

.left-44 {
  left: 11rem;
}

.left-48 {
  left: 12rem;
}

.left-5 {
  left: 1.25rem;
}

.left-52 {
  left: 13rem;
}

.left-56 {
  left: 14rem;
}

.left-6 {
  left: 1.5rem;
}

.left-60 {
  left: 15rem;
}

.left-64 {
  left: 16rem;
}

.left-7 {
  left: 1.75rem;
}

.left-72 {
  left: 18rem;
}

.left-8 {
  left: 2rem;
}

.left-80 {
  left: 20rem;
}

.left-9 {
  left: 2.25rem;
}

.left-96 {
  left: 24rem;
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-10 {
  right: 2.5rem;
}

.right-11 {
  right: 2.75rem;
}

.right-12 {
  right: 3rem;
}

.right-14 {
  right: 3.5rem;
}

.right-16 {
  right: 4rem;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-28 {
  right: 7rem;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-32 {
  right: 8rem;
}

.right-36 {
  right: 9rem;
}

.right-4 {
  right: 1rem;
}

.right-40 {
  right: 10rem;
}

.right-44 {
  right: 11rem;
}

.right-48 {
  right: 12rem;
}

.right-5 {
  right: 1.25rem;
}

.right-52 {
  right: 13rem;
}

.right-56 {
  right: 14rem;
}

.right-6 {
  right: 1.5rem;
}

.right-60 {
  right: 15rem;
}

.right-64 {
  right: 16rem;
}

.right-7 {
  right: 1.75rem;
}

.right-72 {
  right: 18rem;
}

.right-8 {
  right: 2rem;
}

.right-80 {
  right: 20rem;
}

.right-9 {
  right: 2.25rem;
}

.right-96 {
  right: 24rem;
}

.right-px {
  right: 1px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-20 {
  top: 5rem;
}

.top-24 {
  top: 6rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-40 {
  top: 10rem;
}

.top-44 {
  top: 11rem;
}

.top-48 {
  top: 12rem;
}

.top-5 {
  top: 1.25rem;
}

.top-52 {
  top: 13rem;
}

.top-56 {
  top: 14rem;
}

.top-6 {
  top: 1.5rem;
}

.top-60 {
  top: 15rem;
}

.top-64 {
  top: 16rem;
}

.top-7 {
  top: 1.75rem;
}

.top-72 {
  top: 18rem;
}

.top-8 {
  top: 2rem;
}

.top-80 {
  top: 20rem;
}

.top-9 {
  top: 2.25rem;
}

.top-96 {
  top: 24rem;
}

.top-px {
  top: 1px;
}

.block {
  display: block;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-1\/4 {
  height: 25%;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-2\/4 {
  height: 50%;
}

.h-2\/5 {
  height: 40%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-3\/4 {
  height: 75%;
}

.h-3\/5 {
  height: 60%;
}

.h-3\/6 {
  height: 50%;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/5 {
  height: 80%;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-min {
  height: min-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/6 {
  width: 50%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-4\/5 {
  width: 80%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.grow {
  flex-grow: 1;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.items-center {
  align-items: center;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-amber-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgb(186 220 255 / var(--tw-border-opacity));
}

.border-card-green {
  --tw-border-opacity: 1;
  border-color: rgb(216 236 196 / var(--tw-border-opacity));
}

.border-card-lilac {
  --tw-border-opacity: 1;
  border-color: rgb(248 204 252 / var(--tw-border-opacity));
}

.border-card-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(255 236 124 / var(--tw-border-opacity));
}

.border-content-border {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 228 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

.border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

.border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

.border-cyan-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

.border-dark-text {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 51 / var(--tw-border-opacity));
}

.border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-emerald-200 {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-emerald-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-emerald-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-emerald-900 {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-emerald-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-fuchsia-100 {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

.border-fuchsia-200 {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

.border-fuchsia-300 {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

.border-fuchsia-400 {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

.border-fuchsia-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

.border-fuchsia-500 {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

.border-fuchsia-600 {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

.border-fuchsia-700 {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

.border-fuchsia-800 {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

.border-fuchsia-900 {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

.border-fuchsia-950 {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-gray-950 {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgb(161 216 132 / var(--tw-border-opacity));
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-grey-border {
  --tw-border-opacity: 1;
  border-color: rgb(193 193 193 / var(--tw-border-opacity));
}

.border-grey-breadcrumb {
  --tw-border-opacity: 1;
  border-color: rgb(249 249 249 / var(--tw-border-opacity));
}

.border-grey-location {
  --tw-border-opacity: 1;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
}

.border-grey-tag {
  --tw-border-opacity: 1;
  border-color: rgb(133 133 133 / var(--tw-border-opacity));
}

.border-grey-text {
  --tw-border-opacity: 1;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

.border-indigo-950 {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

.border-inherit {
  border-color: inherit;
}

.border-light-green {
  --tw-border-opacity: 1;
  border-color: rgb(174 203 112 / var(--tw-border-opacity));
}

.border-light-grey {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-light-purple {
  --tw-border-opacity: 1;
  border-color: rgb(241 202 255 / var(--tw-border-opacity));
}

.border-light-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(255 232 128 / var(--tw-border-opacity));
}

.border-lilac {
  --tw-border-opacity: 1;
  border-color: rgb(227 149 255 / var(--tw-border-opacity));
}

.border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-lime-950 {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-menu-border {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.border-neutral-950 {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.border-orange {
  --tw-border-opacity: 1;
  border-color: rgb(255 153 0 / var(--tw-border-opacity));
}

.border-pink {
  --tw-border-opacity: 1;
  border-color: rgb(246 209 228 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
}

.border-purple {
  --tw-border-opacity: 1;
  border-color: rgb(179 170 255 / var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-report-bg {
  --tw-border-opacity: 1;
  border-color: rgb(241 241 241 / var(--tw-border-opacity));
}

.border-report-border {
  --tw-border-opacity: 1;
  border-color: rgb(187 187 187 / var(--tw-border-opacity));
}

.border-rose-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.border-rose-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

.border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.border-rose-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

.border-rose-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.border-rose-600 {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

.border-rose-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

.border-rose-800 {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

.border-rose-900 {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

.border-rose-950 {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

.border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

.border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

.border-slate-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

.border-stone-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

.border-stone-200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.border-stone-400 {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.border-stone-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

.border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

.border-stone-600 {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.border-stone-700 {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.border-stone-900 {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

.border-stone-950 {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

.border-table-border {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity));
}

.border-table-row {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-teal-100 {
  --tw-border-opacity: 1;
  border-color: rgb(204 251 241 / var(--tw-border-opacity));
}

.border-teal-200 {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity));
}

.border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

.border-teal-400 {
  --tw-border-opacity: 1;
  border-color: rgb(45 212 191 / var(--tw-border-opacity));
}

.border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

.border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

.border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

.border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}

.border-teal-900 {
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

.border-teal-950 {
  --tw-border-opacity: 1;
  border-color: rgb(4 47 46 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-violet-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.border-violet-200 {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

.border-violet-300 {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.border-violet-400 {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.border-violet-50 {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.border-violet-700 {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

.border-violet-800 {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

.border-violet-900 {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.border-violet-950 {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(247 205 86 / var(--tw-border-opacity));
}

.border-zinc-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-zinc-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.border-zinc-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-zinc-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.border-zinc-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.border-zinc-700 {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

.border-zinc-800 {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.border-zinc-900 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

.border-zinc-950 {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
}

.bg-card-green {
  --tw-bg-opacity: 1;
  background-color: rgb(216 236 196 / var(--tw-bg-opacity));
}

.bg-card-lilac {
  --tw-bg-opacity: 1;
  background-color: rgb(248 204 252 / var(--tw-bg-opacity));
}

.bg-card-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 236 124 / var(--tw-bg-opacity));
}

.bg-content-border {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 228 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.bg-cyan-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.bg-dark-text {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.bg-fuchsia-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(161 216 132 / var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.bg-grey-border {
  --tw-bg-opacity: 1;
  background-color: rgb(193 193 193 / var(--tw-bg-opacity));
}

.bg-grey-breadcrumb {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-grey-location {
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.bg-grey-tag {
  --tw-bg-opacity: 1;
  background-color: rgb(133 133 133 / var(--tw-bg-opacity));
}

.bg-grey-text {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.bg-indigo-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-light-green {
  --tw-bg-opacity: 1;
  background-color: rgb(174 203 112 / var(--tw-bg-opacity));
}

.bg-light-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-light-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(241 202 255 / var(--tw-bg-opacity));
}

.bg-light-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 128 / var(--tw-bg-opacity));
}

.bg-lilac {
  --tw-bg-opacity: 1;
  background-color: rgb(227 149 255 / var(--tw-bg-opacity));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.bg-menu-border {
  --tw-bg-opacity: 1;
  background-color: rgb(230 230 230 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(255 153 0 / var(--tw-bg-opacity));
}

.bg-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(246 209 228 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(179 170 255 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-report-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

.bg-report-border {
  --tw-bg-opacity: 1;
  background-color: rgb(187 187 187 / var(--tw-bg-opacity));
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.bg-rose-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.bg-stone-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.bg-table-border {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.bg-table-row {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

.bg-teal-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-violet-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(247 205 86 / var(--tw-bg-opacity));
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-4 {
  padding: 1rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-72 {
  padding: 18rem;
}

.p-8 {
  padding: 2rem;
}

.p-80 {
  padding: 20rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pt-12 {
  padding-top: 3rem;
}

.text-2xl {
  font-size: 2.222rem;
}

.text-3xl {
  font-size: 4.444rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-base {
  font-size: 18px;
}

.text-l {
  font-size: 1.333rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .889rem;
}

.text-xl {
  font-size: 1.778rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-zero {
  font-size: 0;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-normal {
  line-height: 1.5;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(186 220 255 / var(--tw-text-opacity));
}

.text-card-green {
  --tw-text-opacity: 1;
  color: rgb(216 236 196 / var(--tw-text-opacity));
}

.text-card-lilac {
  --tw-text-opacity: 1;
  color: rgb(248 204 252 / var(--tw-text-opacity));
}

.text-card-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 236 124 / var(--tw-text-opacity));
}

.text-content-border {
  --tw-text-opacity: 1;
  color: rgb(228 228 228 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.text-dark-text {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.text-fuchsia-950 {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(161 216 132 / var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity));
}

.text-grey-border {
  --tw-text-opacity: 1;
  color: rgb(193 193 193 / var(--tw-text-opacity));
}

.text-grey-breadcrumb {
  --tw-text-opacity: 1;
  color: rgb(249 249 249 / var(--tw-text-opacity));
}

.text-grey-location {
  --tw-text-opacity: 1;
  color: rgb(222 222 222 / var(--tw-text-opacity));
}

.text-grey-tag {
  --tw-text-opacity: 1;
  color: rgb(133 133 133 / var(--tw-text-opacity));
}

.text-grey-text {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-light-green {
  --tw-text-opacity: 1;
  color: rgb(174 203 112 / var(--tw-text-opacity));
}

.text-light-grey {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-light-purple {
  --tw-text-opacity: 1;
  color: rgb(241 202 255 / var(--tw-text-opacity));
}

.text-light-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 232 128 / var(--tw-text-opacity));
}

.text-lilac {
  --tw-text-opacity: 1;
  color: rgb(227 149 255 / var(--tw-text-opacity));
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-menu-border {
  --tw-text-opacity: 1;
  color: rgb(230 230 230 / var(--tw-text-opacity));
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(255 153 0 / var(--tw-text-opacity));
}

.text-pink {
  --tw-text-opacity: 1;
  color: rgb(246 209 228 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgb(179 170 255 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-report-bg {
  --tw-text-opacity: 1;
  color: rgb(241 241 241 / var(--tw-text-opacity));
}

.text-report-border {
  --tw-text-opacity: 1;
  color: rgb(187 187 187 / var(--tw-text-opacity));
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.text-rose-300 {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-rose-50 {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.text-rose-950 {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-slate-950 {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.text-stone-400 {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.text-stone-950 {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.text-table-border {
  --tw-text-opacity: 1;
  color: rgb(224 224 224 / var(--tw-text-opacity));
}

.text-table-row {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.text-teal-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.text-teal-950 {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.text-violet-300 {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.text-violet-50 {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.text-violet-950 {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(247 205 86 / var(--tw-text-opacity));
}

.text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

:root {
  --wrapper-width: 820px;
  --wide-wrapper-width: 1030px;
  --thin-wrapper-width: 715px;
  --full-wrapper-width: 1240px;
}

@font-face {
  font-family: Atkinson Hyperlegible;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("AtkinsonHyperlegible-Regular.ffdbe831.ttf") format("truetype");
}

@font-face {
  font-family: Atkinson Hyperlegible;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("AtkinsonHyperlegible-Italic.91fcf4d4.ttf") format("truetype");
}

@font-face {
  font-family: Atkinson Hyperlegible;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("AtkinsonHyperlegible-Bold.284b54e7.ttf") format("truetype");
}

@font-face {
  font-family: Atkinson Hyperlegible;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("AtkinsonHyperlegible-BoldItalic.8f098aaa.ttf") format("truetype");
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url("RobotoSlab-VariableFont_wght.c287b125.ttf") format("truetype");
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, blockquote, p, address, hr, fieldset, figure, pre {
  margin: 0;
  padding-bottom: 1rem;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, ul:last-child, ol:last-child, dl:last-child, blockquote:last-child, p:last-child, address:last-child, hr:last-child, fieldset:last-child, figure:last-child, pre:last-child {
  padding-bottom: 0;
}

ul, ol, dd {
  padding-left: 1rem;
}

html {
  font-size: 18px;
}

body {
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Arial, serif;
  line-height: 1.5;
}

input, select, textarea, button {
  font: inherit;
}

h1, h2, h3, h4, h5 {
  font-family: Roboto Slab, sans-serif;
  font-weight: 700;
  line-height: 1.25;
}

h1 {
  font-size: 2.222rem;
}

@media (min-width: 769px) {
  h1.large {
    font-size: 4.444rem;
  }
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.778rem;
}

h4 {
  font-size: 1.333rem;
}

h5 {
  margin-bottom: .5rem;
  font-size: 18px;
}

h6 {
  margin-bottom: .5rem;
  font-family: Roboto Slab, sans-serif;
  font-size: 18px;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, p, blockquote, address {
  margin-bottom: 1rem;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration-line: underline;
}

p + h1, p + h2, p + h3, p + h4, p + h5, p + h6, ol + h1, ol + h2, ol + h3, ol + h4, ol + h5, ol + h6, ul + h1, ul + h2, ul + h3, ul + h4, ul + h5, ul + h6 {
  padding-top: 1rem;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

html, body {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

img {
  max-width: 100%;
}

iframe {
  display: block;
}

hr {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  border-width: 0;
  height: 1px;
  margin-bottom: 1rem;
  padding: 0;
}

hr.spacious {
  margin-bottom: 4.056rem;
}

p {
  margin-bottom: 0;
}

input::placeholder, select::placeholder, textarea::placeholder, .search-bar__multi-select::placeholder {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  opacity: 1;
}

input, select, textarea, .search-bar__multi-select {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(193 193 193 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  border-radius: .222rem;
  width: 100%;
  padding: .667rem .557rem;
  display: block;
}

input[disabled], select[disabled], textarea[disabled], .search-bar__multi-select[disabled] {
  cursor: default;
  opacity: .65;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: middle;
  margin-right: .5rem;
  display: inline-block;
  width: 1rem !important;
  height: 1rem !important;
}

select, .search-bar__multi-select {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("chevron-down.8323c204.svg");
  background-position: right 1.111rem center;
  background-repeat: no-repeat;
  padding: .5rem 3rem .5rem .5rem;
}

select::-ms-expand {
  display: none;
}

.search-bar__multi-select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  height: auto;
  min-height: 8rem;
}

label, .label {
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  width: 100%;
  padding-bottom: .444rem;
  font-size: .889rem;
  font-weight: 700;
  display: block;
  position: relative;
}

label:last-child, .label:last-child {
  padding-bottom: 0;
}

label span, .label span {
  vertical-align: middle;
  display: inline-block;
}

fieldset {
  border-width: 0;
  margin: 0;
  padding-bottom: 1.333rem;
}

fieldset .field {
  width: 100%;
}

fieldset.half {
  flex-wrap: wrap;
  gap: .444rem;
  display: flex;
}

@media (min-width: 561px) {
  fieldset.half {
    flex-wrap: nowrap;
  }

  fieldset.half .field {
    flex: 0 0 50%;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 561px) {
  fieldset.half .field {
    flex: 0 calc(50% - .222rem);
  }
}

table {
  border-collapse: collapse;
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  width: 100%;
  padding: 0;
}

table th, table td {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  text-align: left;
  padding: .5rem 1rem;
}

table th {
  font-weight: 700;
}

table tbody tr:nth-child(odd) td {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

table .centered {
  text-align: center;
}

table .right {
  text-align: right;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.wrapper {
  box-sizing: content-box;
  max-width: var(--wrapper-width);
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

.wrapper > .wrapper {
  padding-left: 0;
  padding-right: 0;
}

.wrapper--wide {
  max-width: var(--wide-wrapper-width);
}

.wrapper--thin {
  max-width: var(--thin-wrapper-width);
}

.wrapper--full {
  max-width: var(--full-wrapper-width);
}

.wrapper:after {
  content: "";
  clear: both;
  visibility: hidden;
  display: block;
}

.field {
  padding-bottom: 1rem;
}

.field:last-child {
  padding-bottom: 0;
}

.field > ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.field > ul label {
  cursor: pointer;
  font-weight: 400;
}

.field > ul li {
  list-style: none;
}

.field.field--checkboxinput label {
  cursor: pointer;
}

.field:after {
  content: "";
  clear: both;
  visibility: hidden;
  display: block;
}

.file-input {
  padding: 0;
  position: relative;
}

.file-input input {
  visibility: hidden;
  max-width: none;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.file-input label {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: .5rem 1rem;
  font-weight: 400;
  display: block;
}

.errorlist {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
  margin-top: 0;
  margin-bottom: 0;
  padding: .5rem 0;
}

.errorlist li {
  list-style: none;
}

.btn {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  text-align: center;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 0;
  border-radius: .667rem;
  padding: .5rem 1rem;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Arial, serif;
  font-size: 1.111rem;
  font-weight: 700;
  text-decoration-line: none;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  display: inline-block;
  position: relative;
}

.btn:hover, .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
}

.btn--yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(247 205 86 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
}

.btn--yellow:hover, .btn--yellow:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(247 205 86 / var(--tw-bg-opacity));
}

.btn--header {
  border-radius: 1.206rem;
  min-width: 8.889rem;
  font-size: 18px;
  font-weight: 400;
}

.btn--arrow img, .btn--arrow svg {
  width: 1.33rem;
  height: auto;
  margin-left: .25rem;
  display: inline;
}

.btn--white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
}

.btn--white:hover, .btn--white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.btn--external {
  padding-right: 2.5rem;
}

.btn--external:after {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: url("external-link.3662b9e3.svg");
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  right: .5rem;
}

.btn--external img {
  display: none !important;
}

.skip-to-content .btn {
  z-index: 50;
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.skip-to-content .btn:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tag span {
  --tw-border-opacity: 1;
  border-width: 1.5px;
  border-color: rgb(133 133 133 / var(--tw-border-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(133 133 133 / var(--tw-text-opacity));
  border-radius: .889rem;
  min-width: 6.778rem;
  padding: .222rem;
  font-size: .833rem;
  font-weight: 400;
  position: relative;
}

.tag input {
  -webkit-appearance: none;
  appearance: none;
  border-width: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.tag input:checked + span {
  --tw-border-opacity: 1;
  border-color: rgb(255 153 0 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 153 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
}

.tag input:checked + span:before {
  content: "";
  background-image: url("tick.ec127366.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: .667rem;
  height: .444rem;
  margin-left: -.278rem;
  margin-right: .278rem;
  display: inline-block;
}

.tag--lilac span {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  border-radius: 1.222rem;
  padding: .333rem 1.611rem;
  font-size: 1.056rem;
}

.tag--lilac input:checked + span {
  --tw-border-opacity: 1;
  border-color: rgb(227 149 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(227 149 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
}

.tag--lilac input:checked + span:before {
  background-image: url("tick--primary.151ac312.svg");
  width: .889rem;
  height: .611rem;
  margin-left: 0;
  margin-right: .667rem;
}

.tag--static span {
  --tw-border-opacity: 1;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  min-width: 4.278rem;
  padding: 1px;
  font-size: .722rem;
  font-weight: 500;
  display: block;
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.glide {
  padding-left: 3rem;
  padding-right: 3rem;
}

.glide--no-padding {
  padding: 0;
}

.glide--no-padding .wrapper {
  width: 100%;
}

.glide__arrow {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: .333rem;
  box-shadow: 0 0 .444rem #0003;
}

.glide__arrow--left {
  left: 2px;
}

.glide__arrow--right {
  right: 2px;
}

.mfp-bg {
  z-index: 1042;
  opacity: .8;
  background: #0b0b0b;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  cursor: auto;
  width: 100%;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  text-align: center;
  z-index: 1044;
  width: auto;
  margin-top: -.8em;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  text-align: center;
  opacity: .65;
  color: #fff;
  width: 44px;
  height: 44px;
  padding: 0 0 18px 10px;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  line-height: 44px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  color: #ccc;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-arrow {
  opacity: .65;
  -webkit-tap-highlight-color: transparent;
  width: 90px;
  height: 110px;
  margin: -55px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  border: inset #0000;
  width: 0;
  height: 0;
  margin-top: 35px;
  margin-left: 35px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  opacity: .7;
  border-top-width: 21px;
  border-bottom-width: 21px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: 900px;
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  background: #000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0009;
}

img.mfp-img {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 40px 0;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  z-index: -1;
  background: #444;
  width: auto;
  height: auto;
  display: block;
  position: absolute;
  inset: 40px 0;
  box-shadow: 0 0 8px #0009;
}

.mfp-figure small {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  cursor: auto;
  width: 100%;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: left;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
  line-height: 18px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    text-align: center;
    background: #0009;
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (max-width: 900px) {
  .mfp-arrow {
    transform: scale(.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-content {
  position: static;
  width: calc(100% - 2rem) !important;
  max-width: 80rem !important;
}

.mfp-figure:after {
  display: none;
}

.mfp-iframe-scaler {
  position: relative;
  overflow: visible;
}

.mfp-arrow {
  filter: drop-shadow(0 0 .777rem #000a);
}

.mfp-arrow:after {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.mfp-arrow-left {
  opacity: 1;
  left: 1rem;
}

.mfp-arrow-left:hover:after {
  --tw-border-opacity: 1;
  border-right-color: rgb(0 38 62 / var(--tw-border-opacity));
}

.mfp-arrow-right {
  opacity: 1;
  right: 1rem;
}

.mfp-arrow-right:hover:after {
  --tw-border-opacity: 1;
  border-left-color: rgb(0 38 62 / var(--tw-border-opacity));
}

.mfp-close {
  opacity: 1;
  padding-right: .5rem;
  right: 0 !important;
}

.mfp-counter {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: .5rem;
  padding-right: .5rem !important;
}

img.mfp-img {
  border-radius: .375rem;
  padding: 0;
  max-height: calc(100vh - 5rem) !important;
}

.mfp-bottom-bar {
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-bg-opacity: .9;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 100%;
  padding: .5rem 1rem;
  font-size: .889rem;
  line-height: 1.5;
  position: absolute;
  bottom: 0;
  left: 0;
}

.person-modal {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .833rem;
  align-items: flex-start;
  max-width: 59rem;
  margin: 1.5rem auto;
  padding: 2rem;
  position: relative;
}

@media (min-width: 769px) {
  .person-modal {
    padding: 3.5rem;
    display: flex;
  }
}

.person-modal .person-modal__image {
  flex: none;
  width: 100%;
  margin-bottom: 1.5rem;
}

@media (min-width: 769px) {
  .person-modal .person-modal__image {
    max-width: 13.5rem;
    margin-bottom: 0;
  }
}

.person-modal .person-modal__image img {
  border-radius: .667rem;
}

@media (min-width: 769px) {
  .person-modal .person-modal__image img {
    width: 100%;
  }
}

.person-modal .person-modal__content {
  flex: auto;
}

@media (min-width: 769px) {
  .person-modal .person-modal__content {
    padding-left: 3.5rem;
  }
}

.person-modal .mfp-close {
  background-image: url("modal-close.f9fb9242.svg");
  width: 2.222rem;
  height: 2.222rem;
  font-size: 0;
  top: 1.611rem;
  right: 1.611rem !important;
}

@media (min-width: 769px) {
  .person-modal .mfp-close {
    top: 1.111rem;
    right: 1.111rem !important;
  }
}

.site-footer {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 4rem;
  padding-bottom: 4.889rem;
  font-size: .889rem;
}

.site-footer__upper {
  border-style: solid;
  border-bottom-width: 1px;
  flex-wrap: wrap;
  gap: 4vw;
  padding-bottom: 3.111rem;
  display: flex;
}

@media (min-width: 961px) {
  .site-footer__upper {
    flex-wrap: nowrap;
  }
}

@media (min-width: 1025px) {
  .site-footer__upper {
    gap: 8.722rem;
  }
}

.site-footer__upper {
  border-color: #eaeff033;
}

.site-footer__upper h4 {
  margin: 0;
  padding-bottom: .222rem;
  font-size: 18px;
}

.site-footer__lower {
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 3.111rem;
  display: flex;
}

@media (min-width: 961px) {
  .site-footer__lower {
    flex-wrap: nowrap;
    gap: 4.444rem;
  }
}

.site-footer__newsletter {
  min-width: 32%;
}

.site-footer__newsletter img {
  max-width: 15.056rem;
  margin-bottom: 2.222rem;
  width: 100% !important;
  height: auto !important;
}

.site-footer__newsletter p {
  padding-bottom: 0;
}

.site-footer__newsletter .field {
  gap: .889rem;
  padding-top: 1rem;
  display: flex;
}

.site-footer__newsletter .field input {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #0000;
  border: 0 solid #eaeff033;
  border-bottom-width: 1px;
  border-radius: 0;
}

.site-footer__newsletter .field input::placeholder {
  color: #ffffffb3;
}

.site-footer__links {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2.667rem;
  width: 100%;
  padding-top: 1rem;
  display: flex;
}

@media (min-width: 961px) {
  .site-footer__links {
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-top: 0;
  }
}

.site-footer__links h4 {
  padding-bottom: .889rem;
}

.site-footer__links ul {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin: 0;
  padding-left: .889rem;
  font-size: .889rem;
}

.site-footer__links ul li {
  padding-bottom: .556rem;
  list-style-image: url("chevron-right--white.ad1cdf6f.svg");
}

.site-footer__links ul li:last-child {
  padding: 0;
}

.site-footer__links ul a {
  text-decoration-line: none;
}

.site-footer__links ul a:hover {
  text-decoration-line: underline;
}

.site-footer__links__list {
  width: 100%;
  max-width: 9.898rem;
}

.site-footer__links__list p a {
  text-decoration-line: none;
  display: block;
}

.site-footer__links__list p a:hover {
  text-decoration-line: underline;
}

.site-footer__links__list .new-tab {
  align-items: center;
  display: flex;
}

.site-footer__links__list .new-tab svg {
  width: 1rem;
  height: 1rem;
}

.site-footer__links__list .social-links {
  gap: .667rem;
  display: flex;
}

.site-footer__links__list .social-links img {
  width: 1rem;
  height: auto;
}

.site-footer__legal {
  align-items: flex-start;
  min-width: 100%;
  font-size: .778rem;
}

@media (min-width: 961px) {
  .site-footer__legal {
    min-width: 27rem;
  }
}

.site-footer__legal__links {
  flex-wrap: wrap;
  gap: 1.611rem;
  padding-bottom: 1.333rem;
  display: flex;
}

.site-footer__logos {
  flex-wrap: wrap;
  align-items: center;
  gap: 1.111rem;
  width: 100%;
  display: flex;
}

@media (min-width: 961px) {
  .site-footer__logos {
    justify-content: flex-end;
  }
}

.site-footer__logos img {
  width: auto;
  max-width: 7.167rem;
  height: auto;
  max-height: 2.472rem;
}

.site-footer__giant {
  width: 100%;
}

@media (min-width: 769px) {
  .site-footer__giant {
    display: flex;
  }
}

@media (min-width: 961px) {
  .site-footer__giant {
    justify-content: flex-end;
  }
}

.site-footer__giant p {
  align-items: center;
  font-size: .889rem;
  display: flex;
}

.site-footer__giant p span {
  padding-right: 1rem;
}

.site-footer__giant p img {
  width: 100%;
  max-width: 9.167rem;
  max-height: none;
}

.site-header {
  padding-bottom: 2.444rem;
  display: block;
}

.site-header.open {
  height: 100vh;
}

.site-header.open .mobile-menu {
  display: block;
}

.site-header__opener {
  cursor: pointer;
  display: block;
}

@media (min-width: 1261px) {
  .site-header__opener {
    display: none;
  }
}

.site-header__upper {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: .611rem;
  padding-bottom: .611rem;
  display: none;
}

@media (min-width: 769px) {
  .site-header__upper {
    display: block;
  }
}

.site-header__upper__container {
  justify-content: flex-end;
  align-items: center;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Arial, serif;
  display: flex;
}

.site-header__upper__container nav {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  margin-top: .333rem;
  margin-bottom: .333rem;
  margin-right: 1.788rem;
}

.site-header__upper__container ul {
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.site-header__upper__container ul a {
  margin-right: 1.788rem;
  text-decoration-line: none;
}

.site-header__upper__container ul a:hover {
  text-decoration-line: underline;
}

.site-header__upper__container ul li {
  list-style: none;
}

.site-header__upper__container form {
  font-size: 0;
}

.site-header__upper__container form .hide {
  display: none;
}

.site-header__upper__container fieldset {
  margin: 0 -.25rem 0 0;
  padding: 0;
  font-size: .889rem;
  display: none;
  position: relative;
}

.site-header__upper__container fieldset.show {
  display: flex;
}

.site-header__upper__container fieldset button {
  order: 1;
  position: absolute;
  top: .44rem;
  left: 1rem;
}

.site-header__upper__container fieldset button img {
  width: 1rem;
  height: auto;
}

.site-header__upper__container fieldset input {
  border-style: none;
  border-radius: 1.21rem;
  order: 2;
  max-width: 16.111rem;
  padding-top: .333rem;
  padding-bottom: .333rem;
  padding-left: 2.72rem;
}

.site-header__lower {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 2.556rem;
  display: block;
}

.site-header__lower__container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.site-header__logo {
  margin-right: 1.5rem;
  font-size: 0;
}

.site-header__logo img {
  width: 100% !important;
  height: auto !important;
}

.fixed-body {
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.site-menu {
  align-items: center;
  display: none;
}

@media (min-width: 1261px) {
  .site-menu {
    display: flex;
  }

  .site-menu__item {
    padding-right: 1.778rem;
  }
}

.site-menu__item:hover > a:not(.btn) {
  --tw-border-opacity: 1;
  border-color: rgb(227 149 255 / var(--tw-border-opacity));
  font-weight: 700;
}

.site-menu__item a:before {
  visibility: hidden;
  --tw-content: attr(title);
  content: var(--tw-content);
  height: 0;
  font-weight: 700;
  display: block;
  overflow: hidden;
}

.site-menu__item a.has-children {
  padding-right: 1.25rem;
  position: relative;
}

.site-menu__item a.has-children:not(.btn):after {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("chevron-down.8323c204.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.site-menu__item a:not(.btn, .visit) {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  padding-top: .333rem;
  padding-bottom: .333rem;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Arial, serif;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.site-menu__item a img {
  display: inline;
}

.site-menu__item a svg {
  vertical-align: middle;
  width: 1rem;
  height: auto;
  margin-bottom: 2px;
  margin-left: .5rem;
  display: inline;
}

.site-menu__item a svg path {
  fill: #00263e;
}

@media (min-width: 1261px) {
  .site-menu__item:last-child {
    padding-right: 0;
  }
}

.site-menu__item ol {
  padding: 0;
  list-style-type: none;
}

.site-menu__item:hover > .site-menu__dropdown {
  visibility: visible;
  z-index: 50;
}

.site-menu__dropdown {
  visibility: hidden;
  z-index: -10;
  width: 100%;
  margin: 0 auto;
  padding-top: 3.4rem;
  position: absolute;
  top: calc(100% - .958rem);
  left: 0;
  right: 0;
}

.site-menu__dropdown__inner {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.site-menu__dropdown__inner > .wrapper {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.site-menu__dropdown__inner .left {
  background-image: url("gradient-bg.2ff6df41.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(50vw - 260px);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.site-menu__dropdown__inner .right {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  width: calc(50vw - 603px);
  height: 100%;
  position: absolute;
  left: 100%;
}

.site-menu__description {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25%;
  min-width: 20.889rem;
  padding: 3.833rem 1rem 4.611rem;
  position: relative;
}

.site-menu__description p {
  z-index: 50;
  max-width: 12.333rem;
  margin: 0;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Arial, serif;
  position: relative;
}

.site-menu__description p.title {
  max-width: none;
  font-family: Roboto Slab, sans-serif;
  font-size: 1.778rem;
  font-weight: 700;
}

.site-menu__description .visit {
  z-index: 50;
  border-style: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.5rem;
  font-weight: 700;
  text-decoration-line: none;
  display: inline-block;
  position: relative;
}

.site-menu__description .visit:after {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("chevron-right.e9d2a20a.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  right: .25rem;
}

.site-menu__children {
  width: 100%;
  padding-top: 2.444rem;
}

.site-menu__children .parent > a {
  padding-right: 1.5rem;
  position: relative;
}

.site-menu__children .parent > a:after {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("chevron-right.e9d2a20a.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  right: .25rem;
}

.site-menu__children li {
  padding-right: 1rem;
}

.site-menu__children li > ol {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(193 193 193 / var(--tw-border-opacity));
}

.site-menu__children li:hover > ol {
  visibility: visible;
}

.site-menu__children > ol {
  float: left;
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(193 193 193 / var(--tw-border-opacity));
  width: 33.3333%;
  padding-left: 2rem;
  position: relative;
}

.site-menu__children > ol ol {
  visibility: hidden;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
}

.site-menu__children > ol ol li {
  padding-left: 1rem;
}

.site-menu__children > ol ol li:hover > a {
  --tw-bg-opacity: 1;
  background-color: rgb(232 232 232 / var(--tw-bg-opacity));
  border-radius: .5rem;
}

.site-menu__children > ol > li {
  padding-right: 1rem;
  display: block;
}

.site-menu__children > ol > li a {
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
}

.site-menu__children > ol > li a:not(.btn) {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  display: inline-block;
}

.site-menu__children > ol > li:hover > a {
  --tw-bg-opacity: 1;
  background-color: rgb(232 232 232 / var(--tw-bg-opacity));
  border-radius: .5rem;
}

.site-menu__children .site-menu__description {
  float: right;
  width: 33.3333%;
  min-width: 0;
  padding: 0;
}

.site-menu__children .site-menu__description .image {
  height: 0;
  padding: 0 0 66.6667%;
  display: block;
  position: relative;
  overflow: hidden;
}

.site-menu__children .site-menu__description img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.site-menu__children .site-menu__description h3, .site-menu__children .site-menu__description p {
  margin-left: .5rem;
  margin-right: .5rem;
}

.site-menu__children:after {
  content: "";
  clear: both;
  visibility: hidden;
  display: block;
}

.mobile-menu {
  z-index: 10;
  --tw-border-opacity: 1;
  border-style: solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  height: calc(100% - 14rem);
  margin-top: 1rem;
  padding: 1rem 1rem .5rem;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.mobile-menu a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-decoration-line: none;
  display: block;
}

.mobile-menu a svg {
  vertical-align: middle;
  width: 1rem;
  height: auto;
  margin-bottom: 2px;
  margin-left: .5rem;
  display: inline;
}

.mobile-menu a svg path {
  fill: #00263e;
}

.mobile-menu a img {
  display: inline;
}

.mobile-menu ol {
  padding: 0;
  list-style-type: none;
}

.mobile-menu ol li {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
}

.mobile-menu .parent > a {
  padding-right: 1rem;
}

.mobile-menu .parent ol {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 100%;
  min-height: 100%;
  transition: left .2s ease-in-out;
  position: absolute;
  top: 0;
  left: calc(100% + 1rem);
}

.mobile-menu .parent.open > ol {
  left: 0;
}

.mobile-menu .back {
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  display: block;
}

.mobile-menu__item {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
  padding-bottom: .25rem;
}

.mobile-menu__items {
  position: relative;
}

.mobile-menu__extra {
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.mobile-menu__extra form {
  --tw-border-opacity: 1;
  border-style: solid;
  border-left-width: 2px;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
  padding-left: 1rem;
}

.accessible-card {
  padding-bottom: 3.889rem;
  display: block;
}

.accessible-card__container {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
  border-radius: .833rem;
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 769px) {
  .accessible-card__container {
    flex-wrap: nowrap;
  }
}

.accessible-card__container {
  box-shadow: 0 0 .444rem #0003;
}

.accessible-card__image {
  min-width: 100%;
}

@media (min-width: 769px) {
  .accessible-card__image {
    min-width: 29%;
  }
}

.accessible-card__image img {
  border-top-left-radius: .833rem;
  border-top-right-radius: .833rem;
  width: 100%;
  height: auto;
}

@media (min-width: 769px) {
  .accessible-card__image img {
    object-fit: cover;
    border-top-right-radius: 0;
    border-bottom-left-radius: .833rem;
    height: 100%;
  }
}

.accessible-card__text {
  padding: 1.5rem;
}

@media (min-width: 769px) {
  .accessible-card__text {
    padding: 3.667rem 3.556rem;
  }
}

.accessible-card__text {
  flex: 100%;
}

.accessible-card__text p {
  font-size: 1.444rem;
  line-height: 2.222rem;
}

.accordions {
  padding-bottom: 3.889rem;
  display: block;
}

.accordions__info {
  padding-bottom: 2.778rem;
  display: block;
}

.accordions__info h2 {
  margin: 0;
  padding: 0;
}

.accordions__section {
  padding-bottom: .222rem;
  display: block;
  box-shadow: 0 0 .444rem #0003;
}

.accordions__section h3 {
  margin: 0;
  padding: 0;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Arial, serif;
  line-height: 1.5;
}

.accordions__section:first-child, .accordions__section:first-child .accordions__title {
  border-top-left-radius: .889rem;
  border-top-right-radius: .889rem;
}

.accordions__section:last-child {
  border-bottom-right-radius: .889rem;
  border-bottom-left-radius: .889rem;
  padding-bottom: 0;
}

.accordions__section:last-child .accordions__title {
  border-bottom-right-radius: .889rem;
  border-bottom-left-radius: .889rem;
}

.accordions__section:last-child.open .accordions__title {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordions__section.open .accordions__content {
  display: block;
}

.accordions__title {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  justify-content: space-between;
  padding: 1.111rem 1.778rem;
  display: flex;
}

.accordions__title h2 {
  margin: 0;
  padding-bottom: 0;
  padding-right: 1rem;
  font-size: 1.5rem;
}

.accordions__content {
  padding-top: .5rem;
  padding-bottom: .556rem;
  display: none;
}

.accordion.open .accordion__opener img {
  transform: rotate(180deg);
}

.accordion.open .accordion__content {
  display: block;
}

.accordion__content {
  padding-bottom: 1.333rem;
  padding-left: 1.778rem;
  padding-right: 1.778rem;
  display: none;
}

.accordion__content :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.accordion__opener {
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  padding: .444rem 1.778rem 1rem;
  font-size: 1.333rem;
  font-weight: 700;
  display: flex;
}

.accordion__opener span {
  text-align: left;
  margin: 0;
  padding: 0 1rem 0 0;
  font-weight: 700;
  display: block;
}

.accordion__opener img {
  transition: transform .2s;
}

.bordered-cards {
  padding-bottom: 4rem;
  display: block;
}

.bordered-cards .bordered-card {
  padding-bottom: 1.111rem;
}

@media screen and (min-width: 1025px) {
  .bordered-cards .bordered-card {
    flex: 0 calc(50% - .6rem);
  }
}

.bordered-cards .bordered-cards__container--stacked .bordered-card {
  padding-bottom: 2.222rem;
}

.bordered-cards .bordered-cards__container--stacked .bordered-card:last-child {
  padding-bottom: 1.111rem;
}

.bordered-card__cards {
  flex-direction: column;
  gap: 4rem;
  display: flex;
}

.bordered-cards__container {
  flex-wrap: wrap;
  gap: 1.111rem;
  display: block;
}

@media (min-width: 1025px) {
  .bordered-cards__container {
    display: flex;
  }
}

.bordered-cards__container .bordered-card__container {
  height: 100%;
}

@media (min-width: 1025px) {
  .bordered-cards__container--stacked {
    display: block;
  }
}

.bordered-card__container--green {
  --tw-bg-opacity: 1;
  background-color: rgb(216 236 196 / var(--tw-bg-opacity));
}

.bordered-card__container--blue {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
}

.bordered-card__container--yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 236 124 / var(--tw-bg-opacity));
}

.bordered-card__container--lilac {
  --tw-bg-opacity: 1;
  background-color: rgb(248 204 252 / var(--tw-bg-opacity));
}

.bordered-card__container--pink {
  --tw-bg-opacity: 1;
  background-color: rgb(246 209 228 / var(--tw-bg-opacity));
}

.bordered-card__container--purple {
  --tw-bg-opacity: 1;
  background-color: rgb(179 170 255 / var(--tw-bg-opacity));
}

.bordered-card {
  padding-bottom: 3.889rem;
  display: block;
}

.bordered-card__container {
  border-radius: .833rem;
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 561px) {
  .bordered-card__container {
    flex-wrap: nowrap;
  }
}

.bordered-card__container {
  box-shadow: 0 0 .444rem #0003;
}

@media (min-width: 561px) {
  .bordered-card__container--image-right .bordered-card__text {
    order: 1;
    padding-left: 2.722rem;
    padding-right: 2.167rem;
  }

  .bordered-card__container--image-right .bordered-card__image {
    order: 2;
  }

  .bordered-card__container--image-right .bordered-card__image img {
    border-radius: 0 .833rem .833rem 0;
  }
}

.bordered-card__image {
  min-width: 100%;
  position: relative;
}

@media (min-width: 561px) {
  .bordered-card__image {
    min-width: 44%;
  }
}

.bordered-card__image img {
  border-top-left-radius: .833rem;
  border-top-right-radius: .833rem;
  width: 100%;
  height: auto;
}

@media (min-width: 561px) {
  .bordered-card__image img {
    object-fit: cover;
    border-top-right-radius: 0;
    border-bottom-left-radius: .833rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.bordered-card__text {
  padding: 1.111rem;
}

@media (min-width: 561px) {
  .bordered-card__text {
    padding: 2.556rem 1.111rem 2.389rem 2.778rem;
  }
}

.bordered-card__text h4 {
  margin-bottom: 0;
  padding-bottom: .889rem;
}

@media (min-width: 561px) {
  .bordered-card__text p {
    max-width: 20.611rem;
  }
}

.bordered-card__text a {
  font-weight: 700;
  text-decoration-line: none;
}

.bordered-card__text a:hover {
  text-decoration-line: underline;
}

.bordered-card__text a img {
  margin-left: .722rem;
  display: inline;
}

.breadcrumb {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
  margin-bottom: 4.056rem;
  padding-top: .778rem;
  padding-bottom: .778rem;
  display: block;
}

.breadcrumb + .hero {
  margin-top: -4.056rem;
}

.breadcrumb ol {
  flex-wrap: wrap;
  align-items: center;
  gap: .889rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.breadcrumb ol .active {
  font-weight: 700;
}

.breadcrumb ol a {
  text-decoration-line: none;
}

.breadcrumb ol a:hover {
  text-decoration-line: underline;
}

.breadcrumb ol li {
  list-style: none;
}

.content-image {
  padding-bottom: 3.889rem;
  display: block;
}

.content-image img {
  border-radius: .667rem;
  width: 100%;
  display: block;
}

.content-image figure {
  padding: 0;
}

.content-image figcaption {
  max-width: 36.556rem;
  padding-top: 1.167rem;
}

.content-image + .text-block {
  margin-top: -1.889rem;
}

.content-video {
  padding-bottom: 3.889rem;
  display: block;
}

.content-video p {
  max-width: 36.556rem;
  padding-top: 1.167rem;
  padding-bottom: 0;
}

.content-video + .text-block {
  margin-top: -1.889rem;
}

.content-video__media {
  display: block;
  position: relative;
}

.content-video__media img {
  border-radius: .667rem;
  width: 100%;
  display: block;
}

.content-video__media .play {
  background-color: #00000040;
  border-radius: .667rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.content-video__media .play img {
  width: 3rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 561px) {
  .content-video__media .play img {
    width: 5.278rem;
  }
}

.cta-card {
  padding-bottom: 3.889rem;
  display: block;
}

.cta-card__container {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 769px) {
  .cta-card__container {
    flex-wrap: nowrap;
    align-items: center;
  }

  .cta-card__container--image-right .cta-card__text {
    order: 1;
    padding-left: 2.722rem;
    padding-right: 2.167rem;
  }
}

@media (min-width: 961px) {
  .cta-card__container--image-right .cta-card__text {
    padding-right: 4.667rem;
  }
}

@media (min-width: 769px) {
  .cta-card__container--image-right .cta-card__image {
    order: 2;
  }
}

.cta-card__image {
  min-width: 100%;
}

@media (min-width: 769px) {
  .cta-card__image {
    min-width: 44%;
  }
}

.cta-card__image img {
  border-radius: .667rem;
  width: 100%;
  height: auto;
}

@media (min-width: 769px) {
  .cta-card__image img {
    object-fit: cover;
    height: 100%;
  }
}

.cta-card__text {
  width: 100%;
  padding: 1.111rem;
}

@media (min-width: 769px) {
  .cta-card__text {
    padding: 2.556rem 1.111rem 2.389rem 2.778rem;
  }
}

@media (min-width: 961px) {
  .cta-card__text {
    padding-left: 4.667rem;
  }
}

.cta-card__text h3 {
  margin-bottom: 0;
  padding-bottom: 1.222rem;
}

.cta-card__text p {
  padding-bottom: 0;
}

@media (min-width: 769px) {
  .cta-card__text p {
    max-width: 24.889rem;
  }
}

.cta-card__text .btn {
  margin-top: 1.778rem;
}

.download {
  padding-bottom: 3.889rem;
  display: block;
}

.download + .download {
  margin-top: -4rem;
}

.download__container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .833rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  display: flex;
}

@media (min-width: 561px) {
  .download__container {
    flex-wrap: nowrap;
  }
}

.download__container {
  box-shadow: 0 0 .444rem #0003;
}

.download__container:last-child {
  margin-bottom: 0;
}

.download__image {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  border-top-left-radius: .833rem;
  border-top-right-radius: .833rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0;
  display: flex;
  position: relative;
}

@media (min-width: 561px) {
  .download__image {
    border-radius: .833rem 0 0 .833rem;
    width: 38%;
    display: block;
  }
}

@media screen and (min-width: 561px) {
  .download__image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.download__image img {
  max-width: 80%;
  height: auto;
  max-height: 80%;
}

.download__info {
  padding: 1.833rem 1.111rem;
}

@media (min-width: 561px) {
  .download__info {
    padding-left: 2.167rem;
    padding-right: 2.167rem;
  }
}

.download__info h4 {
  margin: 0;
  padding-bottom: .222rem;
}

.download__info span {
  padding-bottom: .667rem;
  font-size: .889rem;
  display: block;
}

.download__info p {
  max-width: 20.444rem;
  margin: 0;
  padding-bottom: 1.333rem;
}

.download__info a {
  margin-bottom: .278rem;
  font-size: .889rem;
  font-weight: 600;
  text-decoration-line: none;
}

.download__info a:hover {
  text-decoration-line: underline;
}

.download__info a img {
  margin-right: .722rem;
  display: inline;
}

.featured-cards {
  padding-bottom: 3.889rem;
  display: block;
}

.featured-cards__info {
  padding-bottom: 2.889rem;
  display: block;
}

.featured-cards__info h2, .featured-cards__info p {
  max-width: 41.556rem;
}

.featured-cards__info h2 {
  padding-bottom: .667rem;
}

.featured-cards__container {
  flex-wrap: wrap;
  gap: 2.278rem;
  display: flex;
}

.featured-cards__card {
  width: 100%;
}

@media (min-width: 769px) {
  .featured-cards__card {
    flex: 0 0 50%;
    width: auto;
  }
}

@media screen and (min-width: 769px) {
  .featured-cards__card {
    flex: 0 calc(50% - 1.139rem);
  }
}

.featured-cards__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .667rem;
  height: 0;
  padding: 0 0 56.25%;
  display: block;
  position: relative;
  overflow: hidden;
}

.featured-cards__text {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 128 / var(--tw-bg-opacity));
  border-radius: 1.111rem;
  max-width: 28.056rem;
  margin-top: -1.111rem;
  margin-left: .5rem;
  margin-right: .5rem;
  padding: 1.111rem;
  position: relative;
}

@media (min-width: 769px) {
  .featured-cards__text {
    margin-top: -2.333rem;
    margin-left: 1.167rem;
    margin-right: 1.167rem;
    padding: 2.333rem 2.222rem 2rem;
  }
}

.featured-cards__text h3 {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

@media (min-width: 769px) {
  .featured-cards__text h3 {
    padding-bottom: 1.222rem;
  }
}

.featured-cards__text p {
  padding: 0;
}

.featured-cards__text .btn {
  margin-top: 1.111rem;
}

@media (min-width: 769px) {
  .featured-cards__text .btn {
    margin-top: 1.778rem;
  }
}

.featured-cards__text--green {
  --tw-bg-opacity: 1;
  background-color: rgb(161 216 132 / var(--tw-bg-opacity));
}

.featured-cards__text--blue {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
}

.featured-cards__text--lilac {
  --tw-bg-opacity: 1;
  background-color: rgb(227 149 255 / var(--tw-bg-opacity));
}

.featured-cards__text--pink {
  --tw-bg-opacity: 1;
  background-color: rgb(246 209 228 / var(--tw-bg-opacity));
}

.featured-cards__text--purple {
  --tw-bg-opacity: 1;
  background-color: rgb(179 170 255 / var(--tw-bg-opacity));
}

.filled-card {
  padding-bottom: 3.889rem;
  display: block;
}

.filled-card__container {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  border-radius: .667rem;
  padding: 1.722rem 2.222rem 2.222rem;
}

.filled-card__container h4, .filled-card__container p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.filled-card__container h4 {
  margin-bottom: 0;
  padding-bottom: 1.889rem;
}

.filled-card__container p {
  margin: 0;
  padding: 0;
  font-size: 1.111rem;
}

.filled-card__container .btn {
  margin-top: 1.333rem;
}

.form-block {
  padding-bottom: 3.889rem;
  display: block;
}

.form-block fieldset:last-of-type {
  padding-bottom: 2.5rem;
}

.form-block__container {
  --tw-bg-opacity: 1;
  background-color: rgb(161 216 132 / var(--tw-bg-opacity));
  border-radius: .833rem;
  padding: 1.889rem 2rem 2.556rem;
  box-shadow: 0 0 .444rem #0003;
}

.form-block__info {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.056rem;
  display: flex;
}

@media (min-width: 561px) {
  .form-block__info {
    flex-wrap: nowrap;
    padding-bottom: 2.444rem;
  }
}

.form-block__image {
  width: 100%;
  max-width: 15.222rem;
}

.form-block__image img {
  object-fit: cover;
  border-radius: .667rem;
  width: 100%;
  max-height: 15.222rem;
}

.form-block__text {
  padding-bottom: 2.056rem;
  padding-right: 0;
}

@media (min-width: 561px) {
  .form-block__text {
    padding-bottom: 0;
    padding-right: 2.056rem;
  }
}

.form-block__text h3 {
  margin: 0;
  padding-bottom: .889rem;
}

.form-block__text p {
  margin: 0;
  padding: 0;
}

@media (min-width: 561px) {
  .form-block__text p {
    max-width: 17.883rem;
  }

  .form-block__text p.wide {
    max-width: none;
  }
}

.full-width-cta {
  padding-bottom: 3.889rem;
  display: block;
}

.full-width-cta__container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

@media (min-width: 961px) {
  .full-width-cta__container {
    min-height: 31.556rem;
  }
}

@media screen and (min-width: 961px) {
  .full-width-cta__container:before {
    content: "";
    background: linear-gradient(#b4bce7 0%, #e0bde4 100%);
    border-radius: 2.111rem;
    width: 65%;
    height: 150%;
    position: absolute;
    bottom: -15%;
    right: -20%;
    transform: rotate(15deg);
  }
}

@media screen and (max-width: 960px) {
  .full-width-cta__container {
    background-image: none !important;
  }

  .full-width-cta__container .wrapper {
    background-image: url("gradient-bg.2ff6df41.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.full-width-cta__image {
  display: block;
}

@media (min-width: 961px) {
  .full-width-cta__image {
    display: none;
  }
}

.full-width-cta__image img {
  width: 100%;
  height: auto;
}

.full-width-cta__text {
  width: 100%;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 961px) {
  .full-width-cta__text {
    width: 40%;
    padding-top: 7.611rem;
    padding-bottom: 4.334rem;
  }
}

.full-width-cta__text h3 {
  padding-bottom: .889rem;
  font-size: 2rem;
  font-weight: 900;
}

.full-width-cta__text p {
  padding-bottom: 0;
  font-size: 1.111rem;
}

.full-width-cta__buttons {
  flex-wrap: wrap;
  flex-shrink: 1;
  align-items: flex-start;
  padding-top: 2.667rem;
  display: flex;
}

.full-width-cta__buttons a {
  margin-bottom: 1.222rem;
  margin-right: 1.222rem;
}

.full-width-cta__buttons a:last-child {
  margin: 0;
}

.gallery {
  padding-bottom: 3.889rem;
  display: block;
}

.gallery img {
  border-radius: .667rem;
}

.gallery__primary {
  font-size: 0;
}

.gallery__primary img {
  width: 100%;
  height: auto;
  margin-bottom: .778rem;
  display: block;
}

.gallery__thumbnails {
  flex-wrap: wrap;
  gap: .25rem;
  display: flex;
}

@media (min-width: 561px) {
  .gallery__thumbnails {
    gap: .778rem;
  }
}

.gallery__thumbnails--carousel {
  display: block;
}

@media (min-width: 561px) {
  .gallery__thumbnail {
    flex: 0 0 25%;
  }
}

.gallery__thumbnail {
  flex: 0 calc(25% - .583rem);
}

@media screen and (max-width: 560px) {
  .gallery__thumbnail {
    flex: 0 calc(50% - .125rem);
  }
}

.gallery__thumbnail img {
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero {
  border-bottom-right-radius: 1.111rem;
  margin-bottom: 4.444rem;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (min-width: 769px) {
  .hero {
    margin-bottom: 6.889rem;
  }
}

.hero.alt .hero__text {
  z-index: 20;
  justify-content: center;
  align-items: flex-end;
  padding-top: 10rem;
  padding-bottom: 5rem;
  position: relative;
  top: 0;
}

.hero.alt .hero__text h1 span {
  background-image: none;
}

.hero.alt .hero__text h2 span {
  background-color: #0000;
}

.hero.alt .hero__image {
  transform: none;
}

.hero.alt .hero__text {
  z-index: 10;
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("gradient-bg.2ff6df41.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: -50%;
}

.hero.alt .hero__text > div {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  max-width: 617px;
}

.hero--large .hero__text {
  min-height: 30rem;
}

@media (min-width: 769px) {
  .hero--large .hero__text {
    min-height: 44.444rem;
  }
}

.hero__slide {
  width: 100%;
  position: relative;
}

.hero__image {
  object-fit: cover;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 1.111rem;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

@media (min-width: 769px) {
  .hero__image {
    right: 2rem;
  }
}

.hero__image {
  transform-origin: 100% 100%;
  transform: rotate(3.5deg)scale(1.1);
}

.hero__text {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 20rem;
  padding-top: 6rem;
  display: flex;
}

@media (min-width: 769px) {
  .hero__text {
    min-height: 30.778rem;
  }
}

.hero__text h1 span, .hero__text h2 span {
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: normal;
  border-radius: .667rem;
  line-height: 1;
  display: inline-block;
}

.hero__text h1 span:last-child, .hero__text h2 span:last-child {
  margin-bottom: 0;
}

.hero__text h1 {
  margin-top: -1.5rem;
}

.hero__text h1.large {
  font-size: 3.22rem;
}

@media (min-width: 769px) {
  .hero__text h1.large {
    font-size: 5.875rem;
  }
}

.hero__text h1 span {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 .556rem;
  padding: .5rem;
}

@media (min-width: 769px) {
  .hero__text h1 span {
    padding-bottom: 1rem;
    padding-left: 1.222rem;
    padding-right: 1.222rem;
  }
}

.hero__text h1 span {
  background-image: url("gradient-bg.2ff6df41.png");
}

.hero__text h2 {
  font-size: 1.333rem;
}

.hero__text h2 span {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: .5rem .889rem;
}

.inline-quote {
  padding-bottom: 3.889rem;
  display: block;
}

.inline-quote blockquote {
  position: relative;
}

@media (min-width: 561px) {
  .inline-quote blockquote {
    padding-left: 4.389rem;
    font-size: 1.333rem;
  }
}

.inline-quote blockquote img {
  width: 2.889rem;
  height: auto;
  margin-bottom: 1rem;
  top: 0;
  left: 0;
}

@media (min-width: 561px) {
  .inline-quote blockquote img {
    margin-bottom: 0;
    position: absolute;
  }
}

.inline-quote footer {
  padding-top: .889rem;
  font-family: Roboto Slab, sans-serif;
  font-weight: 700;
}

.inline-quote__container {
  flex-wrap: wrap;
  text-decoration-line: none;
  display: flex;
}

@media (min-width: 561px) {
  .inline-quote__container {
    flex-wrap: nowrap;
  }
}

@media (min-width: 769px) {
  .inline-quote__container blockquote {
    padding-left: 6rem;
  }
}

.inline-quote__container blockquote img {
  left: .75rem;
}

@media (min-width: 769px) {
  .inline-quote__container blockquote img {
    left: 1.333rem;
  }
}

@media (min-width: 561px) {
  .inline-quote__container--image-right blockquote {
    order: 1;
    padding-right: 1.333rem;
  }
}

.inline-quote__container--image-right blockquote img {
  left: 0;
}

@media (min-width: 561px) {
  .inline-quote__container--image-right .inline-quote__image {
    order: 2;
  }
}

.inline-quote__image {
  width: 100%;
  max-width: none;
  padding-bottom: 1.111rem;
}

@media (min-width: 561px) {
  .inline-quote__image {
    max-width: 13.389rem;
    padding-bottom: 0;
  }
}

.inline-quote__image img {
  border-radius: .667rem;
  width: 100%;
}

.list-card {
  padding-bottom: 3.889rem;
  display: block;
}

.list-card__container {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
  border-radius: .833rem;
  box-shadow: 0 0 .444rem #0003;
}

.list-card__container--pink {
  --tw-bg-opacity: 1;
  background-color: rgb(246 209 228 / var(--tw-bg-opacity));
}

.list-card__title {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  border-top-left-radius: .833rem;
  border-top-right-radius: .833rem;
  width: 100%;
  padding: 1rem 2.222rem;
  display: block;
}

.list-card__title h3, .list-card__title h4 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin: 0;
  padding: 0;
}

.list-card__title h4 {
  padding-top: .611rem;
  padding-bottom: .611rem;
}

.list-card__lists {
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2.222rem 2.222rem 2.778rem;
  display: flex;
}

@media (min-width: 561px) {
  .list-card__lists {
    flex-wrap: nowrap;
  }
}

.list-card__lists ul {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: .889rem;
}

@media (min-width: 561px) {
  .list-card__lists ul {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: 769px) {
  .list-card__lists ul {
    flex: 0 calc(50% - .6rem);
  }
}

.list-card__lists ul li {
  padding-bottom: 1rem;
}

.list-card__lists ul li:last-child {
  padding-bottom: 0;
}

.list-card__event {
  padding: 1.111rem;
}

@media (min-width: 561px) {
  .list-card__event {
    padding: 2.222rem;
  }
}

.list-card__event ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.list-card__event ul li {
  padding-bottom: 1.111rem;
  display: flex;
}

@media (min-width: 561px) {
  .list-card__event ul li {
    padding-bottom: 1.333rem;
  }
}

.list-card__event ul img {
  width: 1.333rem;
  height: auto;
  margin-right: .833rem;
}

.list-card__event ul strong {
  display: block;
}

.list-card__event ul li {
  list-style: none;
}

.list-card__address {
  gap: .778rem;
  padding: 2rem 2.222rem;
  display: block;
}

@media (min-width: 561px) {
  .list-card__address {
    display: flex;
  }
}

.list-card__address p {
  padding: 0;
  font-size: .889rem;
  line-height: 1.75rem;
}

.list-card__address__left {
  padding-bottom: 1rem;
}

@media (min-width: 561px) {
  .list-card__address__left {
    padding-bottom: 0;
  }
}

.list-card__address__left {
  flex: 0 40%;
}

.list-card__address__right p {
  flex-wrap: wrap;
  padding-bottom: .25rem;
  display: flex;
}

.list-card__address__right p span {
  margin-right: .667rem;
  font-weight: 700;
}

.list-card__address__right p:last-child {
  padding-bottom: 0;
}

.location-cards {
  padding-bottom: 3.889rem;
  display: block;
}

.location-cards .title {
  margin: 0;
  padding-bottom: 3.278rem;
}

.location-cards__card {
  border-radius: .667rem;
  margin-bottom: 2.222rem;
  box-shadow: 0 0 .444rem #0003;
}

.location-cards__card:last-child {
  margin: 0;
}

.location-cards__card--light .location-cards__card__header {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  padding: 1.111rem .889rem 1.429rem;
}

.location-cards__card--light .location-cards__card__header h4 {
  align-items: center;
  padding: 0;
  font-size: 18px;
  display: flex;
}

.location-cards__card--light .location-cards__card__header h4 img {
  margin-right: .444rem;
}

.location-cards__card__header {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-top-left-radius: .667rem;
  border-top-right-radius: .667rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .833rem 1.111rem 1.5rem 2.111rem;
  display: flex;
}

.location-cards__card__header h4 {
  align-items: center;
  margin: 0;
  padding: .25rem 1.111rem 0 0;
}

@media (min-width: 561px) {
  .location-cards__card__header h4 {
    display: flex;
  }
}

.location-cards__card__header h4 span {
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-weight: 400;
  display: block;
}

@media (min-width: 561px) {
  .location-cards__card__header h4 span {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
  }
}

.location-cards__card__header h4 span.pipe {
  display: none;
}

@media (min-width: 561px) {
  .location-cards__card__header h4 span.pipe {
    display: block;
  }
}

.location-cards__card__header .info {
  align-items: center;
  padding-top: .5rem;
  display: flex;
}

.location-cards__card__header .info p {
  padding: 0;
}

.location-cards__card__header .info p + .btn {
  margin-left: 1.889rem;
}

.location-cards__card__body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-bottom-right-radius: .667rem;
  border-bottom-left-radius: .667rem;
}

@media (min-width: 561px) {
  .location-cards__card__body {
    display: flex;
  }
}

.location-cards__card__image {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

@media (min-width: 561px) {
  .location-cards__card__image {
    border-bottom-left-radius: .667rem;
    min-width: 11.111rem;
  }
}

.location-cards__card__image {
  flex: 0 33%;
}

.location-cards__card__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (min-width: 561px) {
  .location-cards__card__image img {
    border-bottom-left-radius: .667rem;
  }
}

.location-cards__card__voids {
  flex-wrap: wrap;
  flex: 1;
  display: flex;
}

@media (min-width: 961px) {
  .location-cards__card__voids {
    flex-wrap: nowrap;
  }
}

.location-cards__card__void {
  --tw-border-opacity: 1;
  border-style: solid;
  border-right-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  flex: 0 0 50%;
  font-size: .889rem;
}

@media (min-width: 961px) {
  .location-cards__card__void {
    flex: 0 0 25%;
  }
}

.location-cards__card__void:first-child, .location-cards__card__void:nth-child(2) {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 2px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
}

@media (min-width: 961px) {
  .location-cards__card__void:first-child, .location-cards__card__void:nth-child(2) {
    border-bottom-width: 0;
  }
}

.location-cards__card__void:nth-child(2n) {
  --tw-border-opacity: 1;
  border-style: solid;
  border-right-width: 0;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
}

@media (min-width: 961px) {
  .location-cards__card__void:nth-child(2n) {
    border-right-width: 1px;
  }
}

.location-cards__card__void:last-child {
  border-right-width: 0;
}

.location-cards__card__void .upper, .location-cards__card__void .lower {
  padding: 1.444rem .667rem;
}

.location-cards__card__void .upper {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  font-weight: 700;
}

.location-cards__card__void .upper img {
  width: 100%;
  max-width: 4.167rem;
  margin: 0 auto 1.167rem;
}

.location-cards__card__void .lower {
  padding-top: 1.167rem;
}

.location-cards__card__voids--stacked {
  display: block;
}

.location-cards__card__voids--stacked .location-cards__card__void {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  display: flex;
}

.location-cards__card__voids--stacked .location-cards__card__void .upper, .location-cards__card__voids--stacked .location-cards__card__void .lower {
  padding-top: .889rem;
  padding-bottom: 1.444rem;
}

.location-cards__card__voids--stacked .location-cards__card__void .upper {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 0;
  border-right-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  text-align: center;
  flex-direction: column;
  flex: 0 0 28%;
  justify-content: center;
  min-width: 5.714rem;
  display: flex;
}

.location-cards__card__voids--stacked .location-cards__card__void .upper img {
  max-width: 1.523rem;
  margin-bottom: .889rem;
}

.location-cards__card__voids--stacked .location-cards__card__void .lower p {
  max-width: 25.944rem;
}

.location-cards__card__voids--stacked .location-cards__card__void:last-child, .location-cards__card__voids--stacked .location-cards__card__void:last-child .upper {
  border-bottom-width: 0;
}

.location-cards__card__voids--stacked .location-cards__card__void:last-child .lower {
  border-width: 0;
}

.location-cards__card__map {
  width: 100%;
  position: relative;
}

@media (min-width: 561px) {
  .location-cards__card__map {
    width: 31%;
    min-width: 11.111rem;
  }
}

@media screen and (max-width: 560px) {
  .location-cards__card__map {
    height: 0;
    padding: 0 0 56.25%;
    display: block;
    position: relative;
    overflow: hidden;
  }
}

.location-cards__card__map .map {
  padding: 0;
  height: 100% !important;
}

.location-cards__card__map .map__map {
  border-radius: 0;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 561px) {
  .location-cards__card__map .map__map {
    border-bottom-left-radius: .667rem;
  }
}

.location-cards__card__info {
  width: 100%;
}

.location-cards__card__info h4 {
  align-items: center;
  margin: 0;
  padding-bottom: .667rem;
  font-size: 18px;
  display: flex;
}

.location-cards__card__info h4 img {
  margin-right: .667rem;
}

.location-cards__card__info p {
  font-size: .889rem;
}

.location-cards__card__info a {
  text-decoration-line: none;
}

.location-cards__card__info .upper {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  flex-flow: column wrap;
  display: flex;
}

@media (min-width: 961px) {
  .location-cards__card__info .upper {
    flex-flow: row;
  }
}

.location-cards__card__info .upper__right, .location-cards__card__info .upper__left {
  flex: 0 0 50%;
}

.location-cards__card__info .upper__left, .location-cards__card__info .upper__block, .location-cards__card__info .lower {
  padding: 1.667rem 1.667rem 1rem;
}

.location-cards__card__info .upper__left {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  flex-direction: column;
  display: flex;
}

@media (min-width: 961px) {
  .location-cards__card__info .upper__left {
    border-bottom-width: 0;
    border-right-width: 1px;
  }
}

.location-cards__card__info .upper__block {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
  display: block;
}

.location-cards__card__info .upper__block:last-child {
  border-width: 0;
}

.location-cards__card__info ul {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1.5rem;
  padding-left: 0;
  display: flex;
}

@media (min-width: 561px) {
  .location-cards__card__info ul {
    justify-content: center;
  }
}

.location-cards__card__info ul li {
  text-align: center;
  flex-direction: column;
  flex: 0 calc(25% - .755rem);
  font-size: .889rem;
  display: flex;
}

.location-cards__card__info ul li img {
  width: 1.444rem;
  height: auto;
  margin: 0 auto .833rem;
}

.location-cards__card__info ul li {
  list-style: none;
}

.map {
  padding-bottom: 3.889rem;
  display: block;
}

.map .list-card {
  padding-top: 1.111rem;
  padding-bottom: 0;
}

.map .list-card__title h3 {
  align-items: center;
  display: flex;
}

.map .list-card__title h3 img {
  width: 1.111rem;
  height: auto;
  margin-right: .889rem;
}

.map__map {
  border-radius: .667rem;
  height: 0;
  min-height: 26.889rem;
  padding: 0 0 56.25%;
  display: block;
  position: relative;
  overflow: hidden;
}

.map__info {
  padding-bottom: 2.889rem;
  display: block;
}

.map__info h2, .map__info p {
  max-width: 41.556rem;
}

.map__info h2 {
  padding-bottom: .667rem;
}

.page-cards {
  padding-bottom: 3.889rem;
  display: block;
}

.page-cards__container {
  flex-wrap: wrap;
  gap: 1.111rem;
  display: flex;
}

@media screen and (min-width: 961px) {
  .page-cards__container--two .page-cards__card {
    flex: 0 calc(50% - .6rem);
  }
}

.page-cards__block-info {
  padding-bottom: 2.778rem;
  display: block;
}

.page-cards__block-info h2, .page-cards__block-info p {
  max-width: 41.556rem;
}

.page-cards__block-info h2 {
  padding-bottom: .667rem;
}

.page-cards__block-info :last-child {
  margin: 0;
  padding: 0;
}

.page-cards__card {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .833rem;
  width: 100%;
  text-decoration-line: none;
}

@media (min-width: 561px) {
  .page-cards__card {
    width: auto;
  }
}

.page-cards__card {
  box-shadow: 0 0 .444rem #0003;
}

.page-cards__card.grow {
  flex-grow: 1;
}

@media (min-width: 961px) {
  .page-cards__card.grow {
    flex-grow: 0;
  }
}

@media screen and (min-width: 961px) {
  .page-cards__card {
    flex: 0 calc(33% - .6rem);
  }
}

@media screen and (min-width: 561px) and (max-width: 960px) {
  .page-cards__card {
    flex: 0 calc(50% - .6rem);
  }
}

.page-cards__card--green {
  --tw-bg-opacity: 1;
  background-color: rgb(216 236 196 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.page-cards__card--blue {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.page-cards__card--yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 236 124 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.page-cards__card--lilac {
  --tw-bg-opacity: 1;
  background-color: rgb(248 204 252 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.page-cards__card--pink {
  --tw-bg-opacity: 1;
  background-color: rgb(246 209 228 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.page-cards__card--purple {
  --tw-bg-opacity: 1;
  background-color: rgb(179 170 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.page-cards__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: .833rem;
  border-top-right-radius: .833rem;
  width: 100%;
  height: 0;
  padding: 0 0 56.25%;
  display: block;
  position: relative;
  overflow: hidden;
}

.page-cards__info {
  border-bottom-right-radius: .833rem;
  border-bottom-left-radius: .833rem;
  padding: 1.611rem;
}

.page-cards__info h4 {
  margin-bottom: 0;
  padding-bottom: .889rem;
}

.page-cards__info .separator {
  color: #0000;
}

.page-cards__info .upper {
  text-transform: uppercase;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: .889rem;
  display: flex;
}

.page-cards__info .upper time {
  padding-right: .5rem;
}

.page-cards__info .lower .link, .page-cards__info .lower .btn {
  margin-top: 2.222rem;
}

.page-cards__info .lower .link {
  font-size: 1.111rem;
  font-weight: 700;
  display: block;
}

.page-cards__info .lower .link img {
  width: 1.33rem;
  height: auto;
  margin-left: .25rem;
  display: inline;
}

.page-cards__info .lower p {
  padding: 0;
}

.page-cards__info ul {
  margin: 0;
  padding: 1.333rem 0 0;
  list-style-type: none;
}

.page-cards__info ul li {
  align-items: center;
  padding-bottom: .889rem;
  font-size: .889rem;
  display: flex;
}

.page-cards__info ul li:last-child {
  padding-bottom: 0;
}

.page-cards__info ul li span:first-of-type {
  width: 1.222rem;
  margin-right: .889rem;
  font-size: 0;
}

.page-cards__cta {
  padding-top: 2.222rem;
}

@media (min-width: 561px) {
  .page-cards__cta {
    padding-top: 4.444rem;
  }
}

.pagination {
  padding-bottom: 3.889rem;
  display: block;
}

.pagination__pages {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .389rem;
  display: flex;
}

.pagination__pages a, .pagination__pages span {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(193 193 193 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  border-radius: .222rem;
  width: 2.278rem;
  height: 2.278rem;
  padding: .333rem;
  font-size: .889rem;
  line-height: 1.75;
  text-decoration-line: none;
  transition: border .2s;
  display: block;
}

.pagination__pages a:hover, .pagination__pages span:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
}

.pagination__pages a.active, .pagination__pages span.active {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  font-weight: 700;
}

.pagination__pages .arrow {
  border-width: 0;
}

.pagination__pages .arrow--prev {
  margin-left: 1.5rem;
}

.pagination__pages .arrow--next {
  margin-right: 1.5rem;
}

.person-card {
  padding-bottom: 3.889rem;
  display: block;
}

.person-card__container {
  flex-wrap: wrap;
  text-decoration-line: none;
  display: flex;
}

@media (min-width: 561px) {
  .person-card__container {
    flex-wrap: nowrap;
  }

  .person-card__container--image-right .person-card__info {
    order: 1;
    padding-left: 0;
    padding-right: 1.333rem;
  }

  .person-card__container--image-right .person-card__image {
    order: 2;
  }
}

.person-card__image {
  width: 100%;
  max-width: none;
}

@media (min-width: 561px) {
  .person-card__image {
    max-width: 13.389rem;
  }
}

.person-card__image img {
  border-radius: .667rem;
  width: 100%;
}

.person-card__info, .person-modal__content {
  padding-top: 1.333rem;
}

@media (min-width: 561px) {
  .person-card__info, .person-modal__content {
    width: 100%;
    max-width: 21.5rem;
    padding-top: 0;
    padding-left: 1.333rem;
  }
}

.person-card__info h4, .person-modal__content h4 {
  margin-bottom: 0;
  padding-bottom: .222rem;
}

.person-card__info span:not(.link), .person-modal__content span:not(.link) {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  padding-bottom: .444rem;
  display: block;
}

.person-card__info p, .person-modal__content p {
  padding-bottom: 1.333rem;
}

.person-card__info .link, .person-modal__content .link {
  font-size: 1.111rem;
  font-weight: 700;
  display: block;
}

.person-card__info .link:hover, .person-modal__content .link:hover {
  text-decoration-line: underline;
}

.person-card__info .link img, .person-modal__content .link img {
  margin-left: .722rem;
  display: inline;
}

.person-modal__content {
  padding-left: 0;
}

@media (min-width: 561px) {
  .person-modal__content {
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .person-modal__content {
    padding-left: 1.333rem;
  }
}

.people {
  padding-bottom: 3.889rem;
  display: block;
}

.people__container {
  border-radius: .833rem;
  flex-wrap: wrap;
  gap: 1.111rem;
  display: flex;
}

.people__container .person-card {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 961px) {
  .people__container .person-card {
    flex: 0 calc(25% - .85rem);
  }
}

@media screen and (min-width: 561px) and (max-width: 960px) {
  .people__container .person-card {
    flex: 0 calc(50% - .6rem);
  }
}

.people__container .person-card__container {
  display: block;
}

.people__container .person-card__info {
  padding-top: 1.333rem;
  padding-left: 0;
  padding-right: 0;
}

.people__container .person-card__image {
  max-width: none;
}

.people__container .person-card__image img {
  width: 100%;
}

.report {
  padding-bottom: 3.889rem;
  display: block;
}

.report__info {
  padding-bottom: 2.778rem;
}

.report__info :last-child {
  margin: 0;
  padding: 0;
}

.report__report {
  border-radius: .889rem;
  box-shadow: 0 0 .444rem #0003;
}

.report__header {
  border-top-left-radius: .889rem;
  border-top-right-radius: .889rem;
  justify-content: space-between;
  align-items: center;
  padding: .889rem;
}

@media (min-width: 561px) {
  .report__header {
    display: flex;
  }
}

.report__header h4 {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  margin: 0;
  padding-bottom: 1rem;
  font-size: 1.444rem;
  font-weight: 400;
}

@media (min-width: 561px) {
  .report__header h4 {
    padding: 0;
  }
}

.report__body__upper {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(187 187 187 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

@media (min-width: 561px) {
  .report__body__upper {
    display: flex;
  }
}

.report__body__info {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(187 187 187 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
  padding: .444rem .889rem;
}

@media (min-width: 561px) {
  .report__body__info {
    border-bottom-width: 0;
    border-right-width: 1px;
    flex: 0 0 33.333%;
  }
}

.report__body__info p {
  padding: 0;
  font-size: .889rem;
}

.report__body__info:last-child {
  border-bottom-width: 0;
  border-right-width: 0;
}

.report__body__lower {
  border-bottom-right-radius: .889rem;
  border-bottom-left-radius: .889rem;
}

@media (min-width: 561px) {
  .report__body__lower {
    display: flex;
  }
}

.report__body__left {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: rgb(187 187 187 / var(--tw-border-opacity));
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

@media (min-width: 561px) {
  .report__body__left {
    border-bottom-width: 0;
    width: 42%;
    min-width: 11.111rem;
  }
}

.report__body__left img {
  width: 100%;
  max-width: 13.333rem;
}

.report__body__right {
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: rgb(187 187 187 / var(--tw-border-opacity));
  flex: 1;
}

@media (min-width: 561px) {
  .report__body__right {
    border-left-width: 1px;
  }
}

.report__body__right ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.report__body__right ul li {
  list-style: none;
}

.report__body__right li {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  justify-content: space-between;
  gap: 1rem;
  padding: .444rem .889rem;
}

@media (min-width: 769px) {
  .report__body__right li {
    display: flex;
  }
}

.report__body__right li:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

.report__body__right li span {
  align-items: center;
  display: flex;
}

.report__body__right li span:first-child {
  padding-bottom: .5rem;
  font-weight: 700;
}

@media (min-width: 769px) {
  .report__body__right li span:first-child {
    padding: 0;
    font-weight: 400;
  }
}

.report__body__right li:first-child {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(187 187 187 / var(--tw-border-opacity));
}

@media (min-width: 769px) {
  .report__body__right li:first-child, .report__body__right li:first-child span {
    font-weight: 700;
  }
}

.report__body__right li:last-child {
  border-bottom-right-radius: .889rem;
  border-bottom-left-radius: .889rem;
}

@media (min-width: 561px) {
  .report__body__right li:last-child {
    border-bottom-left-radius: 0;
  }
}

.report__body__right li img {
  margin-left: .444rem;
}

.results-list {
  padding-bottom: 3.889rem;
  display: block;
}

.results-list a {
  text-decoration-line: none;
}

.results-list__item {
  width: 100%;
  margin-bottom: 1.111rem;
  padding: 0;
  text-decoration-line: none;
  display: block;
}

.results-list__item:hover h3 {
  text-decoration-line: underline;
}

.results-list__item p {
  padding: 0;
}

.search-bar {
  padding-bottom: 3.889rem;
  display: block;
}

.search-bar h2 {
  margin-bottom: .5rem;
  padding-bottom: 0;
  font-size: 18px;
}

.search-bar + .search-bar, .search-bar + .tags {
  margin-top: -2.722rem;
}

.search-bar + .page-cards {
  margin-top: -1.778rem;
}

.search-bar h3 {
  margin: 0;
  padding-bottom: 1.333rem;
}

.search-bar form {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

@media (min-width: 769px) {
  .search-bar form {
    flex-wrap: nowrap;
  }
}

.search-bar form label {
  padding-bottom: 0;
}

@media (min-width: 769px) {
  .search-bar form.wrap {
    flex-wrap: wrap;
  }
}

.search-bar form.wrap .tags {
  width: 100%;
  padding-top: 2.389rem;
  padding-bottom: 0;
}

.search-bar form.wrap .tags input {
  position: absolute;
}

.search-bar form input, .search-bar form select, .search-bar form .search-bar__multi-select {
  height: 3rem;
  margin-bottom: .5rem;
  position: relative;
}

@media (min-width: 769px) {
  .search-bar form input, .search-bar form select, .search-bar form .search-bar__multi-select {
    border-right-width: 0;
    border-radius: .25rem 0 0 .25rem;
    margin-bottom: 0;
  }
}

.search-bar form input {
  padding: .5rem 3rem .5rem .5rem;
}

@media (min-width: 769px) {
  .search-bar form select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.search-bar form input {
  width: 100%;
  max-width: 100%;
}

.search-bar form select, .search-bar form .search-bar__multi-select {
  width: 100%;
  max-width: 100%;
  height: 3rem;
  padding-top: .35rem;
}

@media (min-width: 769px) {
  .search-bar form select, .search-bar form .search-bar__multi-select {
    width: auto;
    max-width: 10rem;
  }
}

.search-bar form > :first-child {
  border-left-width: 1px;
  border-top-left-radius: .444rem;
  border-bottom-left-radius: .444rem;
}

@media (min-width: 769px) {
  .search-bar form > :last-child {
    border-right-width: 0;
  }
}

.search-bar form button {
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-position: center;
  background-repeat: no-repeat;
  height: 3rem;
  margin-top: .5rem;
}

@media (min-width: 769px) {
  .search-bar form button {
    border-radius: 0 .444rem .444rem 0;
    margin-top: 0;
    font-size: 0;
  }
}

@media screen and (min-width: 769px) {
  .search-bar form button {
    background-image: url("search.a4ca8201.svg");
    flex: 0 0 3rem;
  }
}

.search-bar form a {
  flex-shrink: 0;
  margin-top: .5rem;
  margin-left: 2rem;
  font-size: .889rem;
  font-weight: 700;
}

@media (min-width: 769px) {
  .search-bar form a {
    margin-top: 0;
  }
}

.search-bar__multi-select.open {
  --tw-border-opacity: 1;
  border-color: rgb(0 38 62 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.search-bar__multi-select.open .search-bar__multi-select__dropdown {
  display: block;
}

.search-bar__multi-select__opener {
  padding-top: .167rem;
}

.search-bar__multi-select__dropdown {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-bottom-right-radius: .444rem;
  border-bottom-left-radius: .444rem;
  width: 100%;
  padding: .5rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.search-bar__multi-select__dropdown div {
  padding-bottom: 1rem;
}

.search-bar__multi-select__dropdown div:last-of-type {
  padding: 0;
}

.search-bar__multi-select__dropdown label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-bottom: .5rem;
  display: flex;
}

.search-bar__multi-select__dropdown label input {
  margin-top: .111rem;
}

.stories-search .search-bar input {
  flex: 0 0 95%;
}

.stories-search .search-bar .btn {
  height: 2.611rem;
}

.share {
  padding-bottom: 3.889rem;
  display: block;
}

.share__container {
  --tw-border-opacity: 1;
  border-style: solid;
  border-top-width: 2px;
  border-color: rgb(193 193 193 / var(--tw-border-opacity));
  flex-wrap: wrap;
  align-items: center;
  gap: .444rem;
  padding-top: 1.333rem;
  display: flex;
}

@media (min-width: 375px) {
  .share__container {
    flex-wrap: nowrap;
  }
}

.share__container p {
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  width: 100%;
  padding-bottom: .25rem;
  font-size: .889rem;
  font-weight: 700;
}

@media (min-width: 375px) {
  .share__container p {
    width: auto;
    padding-bottom: 0;
    padding-right: .833rem;
  }
}

.share__container img {
  width: 1.323rem;
}

.share__container img:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.stats {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 3.889rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: block;
}

@media (min-width: 769px) {
  .stats {
    padding-top: 6.222rem;
    padding-bottom: 6.222rem;
  }
}

.stats {
  background-image: url("gradient-bg.2ff6df41.png");
}

.stats__text {
  max-width: 48rem;
  padding-bottom: 2rem;
}

@media (min-width: 769px) {
  .stats__text {
    padding-bottom: 4rem;
  }
}

.stats__text h2 {
  margin-bottom: 0;
  padding-bottom: .667rem;
}

.stats__text p {
  margin: 0;
  padding: 0;
  font-size: 1.111rem;
}

.stats__stats {
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 2rem;
  display: block;
}

@media (min-width: 769px) {
  .stats__stats {
    gap: 2.5rem;
    padding-bottom: 3.333rem;
    display: flex;
  }
}

@media (min-width: 1025px) {
  .stats__stats {
    gap: 3.889rem;
  }
}

.stats__stats .stat {
  padding-bottom: 1rem;
}

@media (min-width: 769px) {
  .stats__stats .stat {
    flex: 0 0 33.333%;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .stats__stats .stat {
    flex: 0 calc(33% - 3rem);
  }
}

.stats__stats .stat span {
  padding-bottom: .5rem;
  font-family: Roboto Slab, sans-serif;
  font-size: 1.778rem;
  font-weight: 600;
  display: block;
}

@media (min-width: 769px) {
  .stats__stats .stat span {
    padding-bottom: 1rem;
    font-size: 5.333rem;
    line-height: 5.778rem;
  }
}

.tags {
  padding-bottom: 3.889rem;
  display: block;
}

.tags + .bordered-cards {
  margin-top: -2.222rem;
}

.tags h3 {
  margin: 0;
  padding-bottom: 1.333rem;
}

.tags--static {
  padding-bottom: 2.167rem;
}

.tags__container {
  flex-wrap: wrap;
  gap: .611rem;
  display: flex;
}

.tags__container--stacked {
  display: block;
}

.text-block {
  padding-bottom: 3.889rem;
  display: block;
}

.text-block + .text-block, .text-block + .tags--static, .text-block + .share, .text-block + .two-col, .text-block + .content-image, .text-block + .content-video {
  margin-top: -1.889rem;
}

.text-block :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.text-block .intro {
  font-size: 1.111rem;
}

.text-block .subtext {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  margin-top: -1rem;
  font-size: 1.333rem;
}

.text-block a {
  font-weight: 700;
}

.text-block--restrained .wrapper * {
  max-width: 39.611rem;
}

.text-block--restrained .wrapper p {
  font-size: 1.111rem;
}

.text-block__two-col {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

@media (min-width: 561px) {
  .text-block__two-col {
    flex-wrap: nowrap;
  }
}

.text-block__two-col ul {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (min-width: 561px) {
  .text-block__two-col ul {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: 561px) {
  .text-block__two-col ul {
    flex: 0 calc(50% - .6rem);
  }
}

.text-block__two-col ul li {
  padding-bottom: 1.5rem;
}

.text-block__two-col ul li:last-child {
  padding-bottom: 0;
}

.text-block__page-info ul {
  flex-wrap: wrap;
  align-items: center;
  gap: 1.111rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

@media (min-width: 769px) {
  .text-block__page-info ul {
    display: flex;
  }
}

@media (min-width: 961px) {
  .text-block__page-info ul {
    gap: 2.222rem;
  }
}

.text-block__page-info ul li {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  padding-bottom: .25rem;
  font-size: .889rem;
}

@media (min-width: 769px) {
  .text-block__page-info ul li {
    padding-bottom: 0;
  }
}

.text-block__page-info ul li.author {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.text-block__page-info ul li img {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
}

.text-block__page-info ul li {
  list-style: none;
}

.text-block--people {
  padding-bottom: 2.778rem;
  display: block;
}

.text-block--people h2, .text-block--people p {
  max-width: 41.556rem;
}

.text-block--people h2 {
  padding-bottom: .667rem;
}

.text-block--people .text :last-child {
  margin: 0;
  padding: 0;
}

.text-block--embed .wrapper * {
  max-width: 100%;
  height: auto;
}

.text-block--sitemap li {
  list-style-type: none;
}

.two-col {
  display: block;
}

.two-col__container {
  flex-direction: column;
  display: flex;
}

@media (min-width: 961px) {
  .two-col__container {
    flex-direction: row;
  }
}

.two-col__container .wrapper {
  max-width: none;
  margin: 0;
  padding: 0;
}

.two-col__left {
  flex: 1;
  order: 2;
}

@media (min-width: 961px) {
  .two-col__left {
    order: 1;
    padding-right: 4.444rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 961px) {
  .two-col__left .location-cards__card__voids {
    flex-wrap: wrap;
  }

  .two-col__left .location-cards__card__void {
    flex: 0 0 50%;
  }

  .two-col__left .location-cards__card__void:first-child, .two-col__left .location-cards__card__void:nth-child(2) {
    --tw-border-opacity: 1;
    border-style: solid;
    border-bottom-width: 2px;
    border-color: rgb(222 222 222 / var(--tw-border-opacity));
  }

  .two-col__left .location-cards__card__void:nth-child(2n) {
    border-right-width: 0;
  }
}

.two-col__right {
  order: 1;
}

@media (min-width: 961px) {
  .two-col__right {
    order: 2;
  }
}

.two-col__right {
  flex: 0 0 24.667rem;
}

@media screen and (max-width: 690px) {
  .two-col__right {
    flex: 1;
  }
}

.vacancies-block {
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4.444rem;
  display: flex;
}

@media (min-width: 769px) {
  .vacancies-block {
    flex-direction: row;
  }
}

.vacancies-block .wrapper {
  padding: 0;
}

.vacancies-block .search-bar h2 {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
}

.vacancies-block__left {
  order: 2;
  width: 100%;
  margin-right: auto;
}

@media (min-width: 769px) {
  .vacancies-block__left {
    order: 1;
    max-width: 28.111rem;
    padding-top: 3.889rem;
  }
}

.vacancies-block__left .location__form {
  width: 100%;
  display: flex;
}

.vacancies-block__right {
  order: 1;
}

.vacancies-block__right img {
  max-width: 35.778rem;
  width: 100% !important;
  height: auto !important;
}

.text-block + .vacancies-block {
  margin-top: -4rem;
}

@media (min-width: 769px) {
  .text-block + .vacancies-block {
    margin-top: -3.889rem;
  }
}

.anchor-bar {
  display: block;
}

.anchor-bar__container {
  --tw-shadow: 0 0 .444rem 0 #0003;
  --tw-shadow-colored: 0 0 .444rem 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-image: url("anchor-bar-gradient.9a77f29e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .75rem;
  margin-bottom: 4.056rem;
  padding: 1.5rem;
}

@media (min-width: 769px) {
  .anchor-bar__container {
    padding: 2.444rem 2.944rem;
  }
}

.anchor-bar__container h2 {
  margin: 0;
  padding-bottom: 1.5rem;
  font-size: 1.333rem;
}

.anchor-bar__container ul {
  margin: 0;
  padding-left: 0;
}

.anchor-bar__container ul li {
  align-items: center;
  gap: .667rem;
  padding-bottom: 1.222rem;
  list-style-type: none;
  display: flex;
}

.anchor-bar__container ul li:last-child {
  padding-bottom: 0;
}

@media (min-width: 769px) {
  .anchor-bar__container ul li {
    gap: .778rem;
  }
}

.anchor-bar__container ul li:before {
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("arrow-right.239910af.svg");
  background-position: center;
  background-size: auto;
  width: 1.333rem;
  min-width: 1.333rem;
  height: 1.333rem;
}

.logo-carousel {
  margin-bottom: 2.5rem;
  display: block;
}

.logo-carousel--large .logo-carousel__image {
  padding: 0 1rem;
}

.logo-carousel__container {
  align-items: center;
  padding: 2rem 0;
  display: flex;
}

.logo-carousel__text {
  padding: 3rem 0 1rem;
}

.logo-carousel__text h2 {
  text-transform: none;
}

.logo-carousel__logos {
  flex: auto;
  overflow: hidden;
}

.logo-carousel__image {
  min-width: 6rem;
  max-width: 8rem;
  padding: 0 .5rem;
}

.logo-carousel__image a, .logo-carousel__image span, .logo-carousel__image img {
  display: block;
}

.logo-carousel__image img {
  object-fit: contain;
  height: 5rem;
}

.steps {
  margin-bottom: 2.5rem;
}

.step {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  border-radius: .75rem;
  margin-bottom: 6rem;
  margin-left: 5rem;
  padding: 1.5rem;
  display: block;
  position: relative;
}

.step:nth-child(4n+2) {
  --tw-bg-opacity: 1;
  background-color: rgb(247 205 86 / var(--tw-bg-opacity));
}

.step:nth-child(4n+2) svg path {
  fill: #f7cd56;
}

.step:nth-child(4n+3) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 202 255 / var(--tw-bg-opacity));
}

.step:nth-child(4n+3) svg path {
  fill: #f1caff;
}

.step:nth-child(4n) {
  --tw-bg-opacity: 1;
  background-color: rgb(174 203 112 / var(--tw-bg-opacity));
}

.step:nth-child(4n) svg path {
  fill: #aecb70;
}

.step.with-image {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 961px) {
  .step.with-image {
    flex-direction: row;
    align-items: center;
  }
}

.step.with-image .step__image {
  object-fit: cover;
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
  width: 100%;
  height: 100%;
  position: relative;
  inset: 0;
}

@media (min-width: 961px) {
  .step.with-image .step__image {
    border-top-left-radius: .75rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: .75rem;
    width: calc(50% - 1.7rem);
    position: absolute;
  }
}

.step.with-image .step__content {
  margin-top: 1rem;
  position: relative;
}

@media (min-width: 961px) {
  .step.with-image .step__content {
    width: 50%;
    margin-top: 0;
    right: -50%;
  }
}

.step:before {
  z-index: 10;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(0 38 62 / var(--tw-bg-opacity));
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 9999px;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: -5rem;
}

.step svg {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  position: absolute;
  top: 100%;
  left: 50%;
}

.step:last-child .bar:not(.bar-up), .step:last-child svg, .step:first-child .bar-up {
  display: none;
}

.step h4, .step p {
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
  padding: 0;
}

.step .bar {
  z-index: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  width: .25rem;
  height: calc(50% + 6rem);
  position: absolute;
  top: 50%;
  left: -3.88rem;
}

.step .bar-up {
  height: 50%;
  top: 0;
}

.video-cards {
  padding-bottom: 3.889rem;
  display: block;
}

.video-cards__container {
  flex-flow: column wrap;
  gap: 2rem;
  display: flex;
}

@media (min-width: 769px) {
  .video-cards__container {
    flex-direction: row;
  }
}

.video-cards__container .content-video {
  padding-bottom: 0;
}

@media (min-width: 769px) {
  .video-cards__container .content-video {
    flex: 0 0 calc(50% - 1rem);
  }
}

.video-cards__container .content-video a {
  margin-bottom: 1.167rem;
}

.video-cards__container .content-video p {
  padding-top: 0;
  padding-bottom: .25rem;
}

.video-cards__container .content-video p strong {
  font-size: 1.333rem;
}

.story-carousel {
  padding-bottom: 3.889rem;
  display: block;
}

.story-carousel .story-carousel__container {
  flex-wrap: wrap;
  display: flex !important;
}

@media (min-width: 769px) {
  .story-carousel .story-carousel__container {
    flex-wrap: nowrap;
  }
}

@media (min-width: 961px) {
  .story-carousel .story-carousel__container {
    align-items: flex-end;
  }
}

.story-carousel .story-carousel__image {
  min-width: 100%;
}

@media (min-width: 769px) {
  .story-carousel .story-carousel__image {
    min-width: 44%;
  }
}

@media (min-width: 961px) {
  .story-carousel .story-carousel__image {
    padding-bottom: 4rem;
  }
}

.story-carousel .story-carousel__image img {
  border-radius: .667rem;
  width: 100%;
  height: auto;
}

@media (min-width: 769px) {
  .story-carousel .story-carousel__image img {
    object-fit: cover;
    height: 100%;
  }
}

.story-carousel .story-carousel__text {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
  border-radius: .667rem;
  margin-top: -1rem;
  margin-left: 0;
  padding: 1.5rem 1rem;
}

@media (min-width: 769px) {
  .story-carousel .story-carousel__text {
    margin-top: 0;
    padding: 1.5rem 3rem;
  }
}

@media (min-width: 961px) {
  .story-carousel .story-carousel__text {
    margin-left: -5rem;
    padding: 2rem 4.25rem;
  }
}

.story-carousel .story-carousel__text h3 {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 1.222rem;
}

.story-carousel .story-carousel__text .story-carousel__text__name {
  text-transform: uppercase;
  padding-bottom: 0;
  font-size: .778rem;
}

.story-carousel .story-carousel__text p {
  padding-bottom: 0;
}

@media (min-width: 769px) {
  .story-carousel .story-carousel__text p {
    max-width: 24.889rem;
  }
}

.story-carousel .story-carousel__text .btn {
  margin-top: 1.778rem;
}

.story-carousel .story-carousel__actions {
  z-index: 50;
  gap: .75rem;
  display: flex;
  position: absolute;
}

@media (min-width: 961px) {
  .story-carousel .story-carousel__actions {
    margin-top: -3rem;
  }
}

.story-carousel .story-carousel__actions span {
  --tw-text-opacity: 1;
  color: rgb(196 196 196 / var(--tw-text-opacity));
  font-weight: 700;
}

.story-carousel .story-carousel__actions span .story-carousel__num__counter {
  --tw-text-opacity: 1;
  color: rgb(0 38 62 / var(--tw-text-opacity));
}

.story-carousel .slick-list {
  overflow-x: hidden;
  height: 100% !important;
}

.person-header {
  border-radius: .75rem;
  flex-direction: column;
  margin-bottom: 4rem;
  padding: 1rem;
  display: flex;
}

@media (min-width: 769px) {
  .person-header {
    padding: 2rem;
  }
}

.person-header--yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(247 205 86 / var(--tw-bg-opacity));
}

.person-header--blue {
  --tw-bg-opacity: 1;
  background-color: rgb(186 220 255 / var(--tw-bg-opacity));
}

.person-header--green {
  --tw-bg-opacity: 1;
  background-color: rgb(161 216 132 / var(--tw-bg-opacity));
}

.person-header--pink {
  --tw-bg-opacity: 1;
  background-color: rgb(246 209 228 / var(--tw-bg-opacity));
}

.person-header--lilac {
  --tw-bg-opacity: 1;
  background-color: rgb(227 149 255 / var(--tw-bg-opacity));
}

.person-header .person-image {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 9999px;
  max-width: 7.777rem;
  max-height: 7.777rem;
}

.person-header .person-name {
  margin: 0;
  padding: 0;
  font-size: 1.111rem;
  display: inline;
}

.person-info {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media (min-width: 769px) {
  .person-info {
    flex-direction: row;
  }
}

.person-info__block {
  flex-direction: column;
  display: flex;
}

.person-info__block .name {
  gap: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.recent-insights {
  padding-bottom: 4rem;
}

.insights-list {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 2rem;
  display: grid;
}

@media (min-width: 769px) {
  .insights-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 961px) {
  .insights-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.insight-item {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex-direction: column;
  gap: .5rem;
  padding: 2rem;
  text-decoration-line: none;
  display: flex;
}

.insight-item .link {
  cursor: pointer;
  margin-top: auto;
  font-size: 1.111rem;
  font-weight: 700;
  display: block;
}

.insight-item .link img {
  width: 1.33rem;
  height: auto;
  margin-left: .25rem;
  display: inline;
}

.insight-item h4 {
  margin: 0;
  padding: 0;
}

.insight-item p {
  font-size: 18px;
}

/*# sourceMappingURL=main.css.map */
