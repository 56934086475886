@import "./tailwind.css";

@import "./1_settings/sizes.css";

@import "./2_tools/mixins.css";
@import "./2_tools/fonts.css";

@import "./3_generic/shared.css";

@import "./4_elements/typography.css";
@import "./4_elements/generic.css";
@import "./4_elements/form.css";
@import "./4_elements/table.css";
@import "./4_elements/slick.css";

@import "./5_atoms/wrapper.css";
@import "./5_atoms/form.css";
@import "./5_atoms/btn.css";
@import "./5_atoms/tag.css";
@import "./5_atoms/glide.css";
@import "./5_atoms/magnific.css";
@import "./5_atoms/person-modal.css";

@import "./6_components/site/footer.css";
@import "./6_components/site/header.css";
@import "./6_components/site/menu.css";
@import "./6_components/accessible-card.css";
@import "./6_components/accordions.css";
@import "./6_components/bordered-cards.css";
@import "./6_components/breadcrumb.css";
@import "./6_components/content-image.css";
@import "./6_components/content-video.css";
@import "./6_components/cta-card.css";
@import "./6_components/download.css";
@import "./6_components/featured-cards.css";
@import "./6_components/filled-card.css";
@import "./6_components/form-block.css";
@import "./6_components/full-width-cta.css";
@import "./6_components/gallery.css";
@import "./6_components/hero.css";
@import "./6_components/inline-quote.css";
@import "./6_components/list-card.css";
@import "./6_components/location-cards.css";
@import "./6_components/map.css";
@import "./6_components/page-cards.css";
@import "./6_components/pagination.css";
@import "./6_components/person-card.css";
@import "./6_components/people.css";
@import "./6_components/report.css";
@import "./6_components/results-list.css";
@import "./6_components/search-bar.css";
@import "./6_components/share.css";
@import "./6_components/stats.css";
@import "./6_components/tags.css";
@import "./6_components/text-block.css";
@import "./6_components/two-col.css";
@import "./6_components/vacancies-block.css";
@import "./6_components/anchor-bar.css";
@import "./6_components/logo-carousel.css";
@import "./6_components/steps.css";
@import "./6_components/video-cards.css";
@import "./6_components/story-carousel.css";
@import "./6_components/person_insights.css"; 

/* @import "./7_overrides/override.css"; */
